import React, { useState, useEffect } from "react";
import axios from "axios";
import "./checkout.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import qs from 'querystring';  // Add this line to import the querystring module
import { useNavigate } from 'react-router-dom';
import Cookie from "js-cookie";
import pinCodesData from '../pages-inner-components/data_components/pincode.json'; // Adjust the path as necessary




const CheckOut = () => {
  const [cart, setCart] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");
  const [subtotal, setSubtotal] = useState(0);
  const [redirectForm, setRedirectForm] = useState(null);  // Add this state
  const [isNewAddress, setIsNewAddress] = useState(false);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(null);
  const [showBillingForm, setShowBillingForm] = useState(false);
  const navigate = useNavigate();
  const [editingAddressIndex, setEditingAddressIndex] = useState(null);
  const [editedAddress, setEditedAddress] = useState({});
  const [refreshAddresses, setRefreshAddresses] = useState(0);
  const type = "shipping";
  const [pincode, setPincode] = useState("");
  const [pincodeStatus, setPincodeStatus] = useState("Not Checked");

  const uuid = require('uuid'); // Import the uuid library

  const handleAddressUpdate = async (index, type, operation = 'update') => {
    try {
        const config = {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        };

        await axios.put(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/updateAddress`, { address: editedAddress, type, index, operation }, config);

        if (operation === 'delete') {
            // Update the local state to remove the address
            setShippingAddresses(prevAddresses => {
                const updatedAddresses = [...prevAddresses];
                updatedAddresses.splice(index, 1);
                return updatedAddresses;
            });
        } else {
            // Reset the edit mode
            setEditingAddressIndex(null);
            
            // Update the local state to reflect the changes
            setShippingAddresses(prevAddresses => {
                const updatedAddresses = [...prevAddresses];
                updatedAddresses[index] = editedAddress;
                return updatedAddresses;
            });
        }

        // Reset editedAddress
        setEditedAddress({});

    } catch (error) {
        console.error(error);
        toast.error('Error updating address');
    }
};
const verifyPincode = (pin) => {
  if (pin.length !== 6) { // Assuming a pin code is 6 digits long
    setPincodeStatus("Not Available");
    return;
  }

  const deliveryAvailable = pinCodesData.some(pinData => pinData['DESTINATION PINCODE'] === pin.trim());

  if (deliveryAvailable) {
    setPincodeStatus("Verified");
  } else {
    setPincodeStatus("Not Available");
  }
};


const handleAddressDelete = async (index, type) => {
    try {
        const config = {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        };

        await axios.put(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/updateAddress`, 
        { action: 'delete', type, index }, config);

        // Update the local state to reflect the changes
        setShippingAddresses(prevAddresses => {
            const updatedAddresses = [...prevAddresses];
            updatedAddresses.splice(index, 1);
            return updatedAddresses;
        });
    } catch (error) {
        console.error(error);
        toast.error('Error deleting address');
    }
};



const fetchCartData = async () => {
  try {
      if (!token) {
          setLoading(false);
          return;
      }

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/getCart`, {
          headers: { Authorization: `Bearer ${token}` },
      });

      const cartData = response.data.cart;

      if (cartData.length) {
          const productRequests = cartData.map((item) =>
              axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/product/${item._id}`)
          );

          const productResponses = await Promise.all(productRequests);

          const updatedProducts = productResponses.map((result, index) => {
              const product = result.data.product;

              // Print the variants from data
              console.log("Variants for product:", product._id, result.data.variants);

              const selectedSize = cartData[index].size;
              let sizePrice = product.prices.price; // Default to product price
              let sizeId; // Placeholder for the size id

              // Extract size-specific price
              const productColor = Object.keys(result.data.variants)[0];
              if (result.data.variants[productColor]) {
                  const sizeInfo = Object.values(result.data.variants[productColor]).find(variant => variant.attr_name === selectedSize);
                  if (sizeInfo) {
                      if (sizeInfo.price) {
                          sizePrice = sizeInfo.price;
                      }
                      sizeId = sizeInfo.size_id; // Extract the size id
                  }
              }

              return {
                  ...product,
                  quantity: cartData[index].quantity,
                  size: selectedSize,
                  sizePrice: sizePrice,
                  size_id: sizeId // Added the size id here
              };
          });

          setProducts(updatedProducts);
          

          const cartSubtotal = updatedProducts.reduce((total, product) => {
              return total + product.sizePrice * product.quantity;
          }, 0);
          

          setSubtotal(cartSubtotal);
      }

      setLoading(false);
  } catch (error) {
      console.error("Error fetching cart:", error);
  }
};

  const sendToCCAvenueHandler = async () => {
    try {
      const orderId = uuid.v4();

      const data = {
        merchant_id: "2836129",
        order_id: orderId, // Use the generated order ID
        currency: "INR",
        amount: (subtotal - 0 + 75).toString(),
        redirect_url: `${process.env.REACT_APP_BACKEND_ENDPOINT}/ccavResponseHandler`,
        cancel_url: `${process.env.REACT_APP_BACKEND_ENDPOINT}/ccavResponseHandler`,
        language: "EN"
      };

      const formattedData = qs.stringify(data);

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/ccavRequestHandler`, formattedData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      if (response.data) {
        // Directly manipulate the document body to add and submit the form
        document.body.innerHTML = response.data;
        const form = document.getElementById("nonseamless");
        if (form) {
          form.submit();
        }
      }
    } catch (error) {
      console.error("Error sending data to CCAvenue:", error);
    }
};
const handleCheckout = async () => {
  try {
    if (!token) {
      return; // Handle the case where the user is not authenticated
    }

    const selectedAddressIndex = document.querySelector('input[name="selectedAddress"]:checked')?.value;

    let selectedAddress = null;
    let saveAddress = false; // Initialize to false

    if (selectedAddressIndex !== null && selectedAddressIndex !== undefined) {
      selectedAddress = shippingAddresses[selectedAddressIndex];
      saveAddress = false; // Address is selected, so don't save it
    } else {
      selectedAddress = {
        address: document.getElementById("address1").value,
        city: document.getElementById("town").value,
        zipCode: document.getElementById("zipcode").value,
        firstName: document.getElementById("first-name").value,
        lastName: document.getElementById("last-name").value,
        email: document.getElementById("email").value,
        phone: document.getElementById("phone").value,
      };
      saveAddress = true; // Address is manually entered, so save it
    }

    if (!selectedAddress.address || !selectedAddress.city || !selectedAddress.zipCode) {
      toast.warn("Please select an address or fill out the address form.");
      return;
    }

    // Gather product details from state
    const orderItems = products.map((product) => ({
      product_id: product._id.toString(),
      title: product.title.en,
      quantity: product.quantity,
      size: product.size,
      sizeId: product.size_id,
      price: product.sizePrice * product.quantity,
    }));

    // Gather order data from form fields
    const orderData = {
      cart: orderItems,
      user_info: {
        shipping_address: selectedAddress,
        billing_address: selectedAddress, // Change this if needed
        first_name: document.getElementById("first-name").value,
        last_name: document.getElementById("last-name").value,
        email: document.getElementById("email").value,
        contact: document.getElementById("phone").value,
      },
      subTotal: subtotal,
      shippingCost: 75,
      discount: 0,
      total: subtotal - 0 + 75,
      shippingOption: "Standard delivery",
      paymentMethod: "COD",
      cardInfo: {},
      status: "Processing",
      saveAddress: saveAddress,
      // Include other order data fields
    };

    // Store the order data in session storage
    Cookie.set("checkoutDetails", JSON.stringify(orderData), { expires: 7 }); // Expires in 7 days

    toast.success("Redirecting You to the Payment Page");
    setTimeout(() => {
      sendToCCAvenueHandler();
    }, 2000);

  } catch (error) {
    console.error("Error preparing order:", error);
    // Handle errors (e.g., show an error message)
  }
};




 

  // const verifyPincode = async (pin) => {
  //   try {
  //     // Make an API call to verify the pincode
  //     const response = await axios.get(
  //       `https://api.postalpincode.in/pincode/${pin}`
  //     );

  //     // Simulated API response

  //     if (response.data[0].Status === "Success") {
  //       setPincodeStatus("Verified");
  //       // Auto-fill the country and city fields based on the API response
  //       document.getElementById("country").value =
  //         response.data[0].PostOffice[0].Country;
  //       document.getElementById("town").value =
  //         response.data[0].PostOffice[0].Block;
  //     } else {
  //       setPincodeStatus("Not Available");
  //     }
  //   } catch (error) {
  //     console.error("Error verifying pincode:", error);
  //     setPincodeStatus("Not Available");
  //   }
  // };

  
  

  useEffect(() => {
    fetchCartData();
    
  }, []);

  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [billingAddresses, setBillingAddresses] = useState([]);

  useEffect(() => {
    // Fetch user's addresses
    axios
      .get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/getAddresses`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const { shipping_address, billing_address } = response.data.Addresses;

        if (shipping_address && shipping_address.length > 0) {
          setShippingAddresses(shipping_address);
          console.log(response.data)
        }

        if (billing_address && billing_address.length > 0) {
          setBillingAddresses(billing_address);
        }
      })
      .catch((error) => {
        console.error("Error fetching addresses:", error);
      });
  }, []);
  if (loading) {
    return <div>Loading...</div>;
  }

  if (subtotal <= 0) {
    // If the subtotal is 0 or less, display a message and hide everything else
    return (
      <div className="no-products-message">
        <h2>No products in cart</h2>
      </div>
    );
  }
  return (
    <>
    {redirectForm ? (
        <div dangerouslySetInnerHTML={{ __html: redirectForm }} />
      ) : (
      <main className="main-wrapper">
        <div className="axil-checkout-area axil-section-gap">
          <div className="container">
          <form action="#" >              <div className="row">
             
                <div className="col-lg-6">
              
                  <div className="axil-checkout-notice">
                  <h4 className="title mb--10">Saved Address</h4>
                    <div className="axil-toggle-box">
                      <div className="axil-checkout-coupon toggle-open">
                        <p>If you have a coupon code, please apply it below.</p>
                        <div className="input-group">
                          <input placeholder="Enter coupon code" type="text" />
                          <div className="apply-btn">
                            <button
                              type="submit"
                              className="axil-btn btn-bg-primary"
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="axil-checkout-billing">
                    

<ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
  {shippingAddresses.map((address, index) => (
    <li key={index}>
      <input
        type="radio"
        name="selectedAddress"
        value={index}
        id={`addressRadio${index}`}
        checked={selectedAddressIndex === index}
        onChange={() => {}}
        onClick={() => {
          if (selectedAddressIndex === index) {
            setSelectedAddressIndex(null); // Deselect if clicked again
          } else {
            setSelectedAddressIndex(index); // Select the address
          }
        }}
      />

{editingAddressIndex === index ? (
                    <>
                    <input 
    type="text"
    className="form-control input-btn"
    defaultValue={address.firstName} 
    onChange={(e) => setEditedAddress({...editedAddress, firstName: e.target.value})}
    placeholder="First Name"
/>
<input 
    type="text"
    className="form-control input-btn"
    defaultValue={address.lastName} 
    onChange={(e) => setEditedAddress({...editedAddress, lastName: e.target.value})}
    placeholder="Last Name"
/>
<input 
    type="text"
    className="form-control input-btn"
    defaultValue={address.phone} 
    onChange={(e) => setEditedAddress({...editedAddress, phone: e.target.value})}
    placeholder="Phone"
/>
                        <input 
                            type="text" 
                            className="form-control input-btn"           
                            defaultValue={address.address} 
                            onChange={(e) => setEditedAddress({...editedAddress, address: e.target.value})}
                        /> 
                        <input 
                            type="text" 
                            className="form-control input-btn"
                            defaultValue={address.city} 
                            onChange={(e) => setEditedAddress({...editedAddress, city: e.target.value})}
                        /> 
                       
                        <input 
                            type="text" 
                            className="form-control input-btn"
                            defaultValue={address.zipCode} 
                            onChange={(e) => setEditedAddress({...editedAddress, zipCode: e.target.value})}
                        />
<button 
    className="axil-btn btn-sm-primary btn-hover" 
    onClick={(e) => {
        e.preventDefault(); // Prevent default form submission
        handleAddressUpdate(index, 'shipping');
    }}
>
    Apply
</button>
                        <br />
                        <br />
                        <button className="axil-btn btn-sm-secondary btn-hover" onClick={() => setEditingAddressIndex(null)}>Cancel</button>
                    </>
      ) : (
        <>
          {/* View mode */}
          <label htmlFor={`addressRadio${index}`}>
            Name: {address.firstName}&nbsp;{address.lastName} <br />
            Phone: {address.phone} <br />
            Address: {address.address} <br />
            {address.city}, <br />
            {address.zipCode}
          </label>
          <button onClick={() => {
    setEditingAddressIndex(index);
    setEditedAddress(address); // Initialize editedAddress with the current address
}}>
    Edit
</button>

<button onClick={(e) => {
    e.preventDefault();
    handleAddressDelete(index, type);
}}>
    Delete
</button>


        </>
      )}
    </li>
  ))}
</ul>
                    </div>

                    <div className="row" onClick={(e) => {
  if (selectedAddressIndex !== null) {
    toast.warn("You have already selected an address");
    e.preventDefault(); // Prevent form actions
  }
}} disabled={selectedAddressIndex !== null}>
                    <h4 className="title mb--20">Add New Address</h4>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>
                            First Name <span>*</span>
                          </label>
                          <input
  type="text"
  id="first-name"
  placeholder="Adam"
  required
  disabled={selectedAddressIndex !== null}
/>

                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>
                            Last Name <span>*</span>
                          </label>
                          <input
                            type="text"
                            id="last-name"
                            placeholder="John"
                            required
                            disabled={selectedAddressIndex !== null}

                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>
                            Town/ Village <span>*</span>
                          </label>
                          <input type="text" id="town"   disabled={selectedAddressIndex !== null}
/>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>
                            City/ District <span>*</span>
                          </label>
                          <input type="text" id="town"  disabled={selectedAddressIndex !== null}
 />
                        </div>
                      </div>
                      <div className="col-lg-6">

<div className="form-group">
  <label>
    Pincode <span>*</span>
  </label>
  <input
    type="text"
    id="zipcode"
    required
    value={pincode}
    onChange={(e) => {
      const newPincode = e.target.value;
      setPincode(newPincode);
      if (newPincode.length === 6) {
        verifyPincode(newPincode);
      } else {
        setPincodeStatus("Not Checked");
      }
    }}
  />
  {pincode && pincodeStatus === "Verified" && (
    <p style={{ color: "green" }}>Delivery Available</p>
  )}
  {pincode && pincodeStatus === "Not Available" && (
    <p style={{ color: "red" }}>Not Available</p>
  )}
</div>


                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>
                            Phone <span>*</span>
                          </label>
                          <input type="tel" id="phone" required   disabled={selectedAddressIndex !== null}
 />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>
                            Email Address <span>*</span>
                          </label>
                          <input type="email" id="email" required   disabled={selectedAddressIndex !== null}
 />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>
                          Street Address <span>*</span>
                        </label>
                        <input
                          type="text"
                          id="address1"
                          className="mb--15"
                          placeholder="House number and street name"
                          disabled={selectedAddressIndex !== null}

                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="axil-order-summery order-checkout-summery">
                    <h5 className="title mb--20">Your Order</h5>
                    <div className="summery-table-wrap">
                      <table className="table summery-table">
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Price</th>
                          </tr>
                        </thead>
                        <tbody>
                        {products.map((product) => (
    <tr className="order-product">
        <td style={{ display: "none" }} id="product_id">
            {product._id}
        </td>
        <td id="product_quantity">
            {product.title.en} (Size: {product.size})*
            <span className="quantity">
                {product.quantity}
            </span>
        </td>
        <td id="product_price">
            Rs.{product.sizePrice * product.quantity}
        </td>
    </tr>
))}

                          <tr className="order-subtotal">
                            <td>
                              <b>Sub-total</b>
                            </td>
                            <td>Rs.{subtotal}</td>
                          </tr>
                          {/* <tr className="order-shipping">
                            <td colspan="2">
                              <div className="shipping-amount">
                                <span className="title">Discount</span>
                                <span className="amount">Rs: 0</span>
                              </div>
                            </td>
                          </tr> */}
                          <tr className="order-shipping">
                                <td colspan="2">
                                  <div className="shipping-amount">
                                    <span className="title">Standard Delivery Charges</span>
                                    <span className="amount">Rs: 75</span>
                                  </div>
                                </td>
                              </tr>

                          
                          <tr className="order-total">
                            <td>Total AMOUNT</td>
                            <td className="order-total-amount">
                              ₹{subtotal - 0 + 75}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <button
  type="button"
  className="axil-btn add-cart-btn-custom checkout-btn"
  onClick={handleCheckout}
>
  Proceed to Checkout
</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
      )}
    </>
  );
};

export default CheckOut;
