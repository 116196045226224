/* eslint-disable react-hooks/exhaustive-deps */
// /* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import "./SingleProductDetails.css";
import { MdFavorite, MdFavoriteBorder } from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProductCard from '../productCard';
import './responsive.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import SimilarProductCarousel from "../SimilarProductsCarousel";
import pinCodesData from './pincode.json';
import { Helmet } from 'react-helmet';













const SingleProductDetails = () => {
  

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate an API call or data loading delay
    setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the delay time as needed
  }, []);
  const [product, setProduct] = useState(null);
  const { id } = useParams(); // Extract the 'category' parameter from the URL
  const { category, productId, productSlug } = useParams();
  const [showAll, setShowAll] = useState(false);
  const [isAddedToWishlist, setIsAddedToWishlist] = useState(false); // To handle the button activation state
  const [triggerRender, setTriggerRender] = useState(false);
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [attributeTitles, setAttributeTitles] = useState([]);
  const [relatedProductsData, setRelatedProductsData] = useState([]);
  const [showWarning, setShowWarning] = useState(false); // Add this line
  const [fullRelatedProductsData, setFullRelatedProductsData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [averageRating, setAverageRating] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [isDescriptionExpanded, setDescriptionExpanded] = useState(false);
  const [pinCode, setPinCode] = useState(''); // State for the pin code input
  const [deliveryInfo, setDeliveryInfo] = useState({ available: false, date: null });

  const [isSizeModalOpen, setSizeModalOpen] = useState(false);

  const openSizeModal = () => setSizeModalOpen(true);
  const closeSizeModal = () => setSizeModalOpen(false);

  const deliveryInfoStyle = {
    padding: '10px',
    marginTop: '10px',
    borderRadius: '5px',
    backgroundColor: '#f5f5f5', // light grey background
    textAlign: 'center',
    color: deliveryInfo.available ? 'green' : 'red', // Text color based on availability
    fontWeight: 'bold'
  };
  const containerStyle = {
    marginTop: '20px', // Reduced top margin for a more compact look
    borderRadius: '5px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    padding: '0px', // Reduced padding for a more compact container
    alignItems: 'center',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80%',
  };


  const inputStyle = {
    padding: '0px', // Reduced padding for a more compact input field
    width: '50%',
    borderRadius: '4px',
    margin: '0px auto', // Reduced margin to decrease space around the input field
  };

  const buttonStyle = {
    padding: '5px', // Reduced padding for a smaller button
    backgroundColor: 'gray',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
    width: '100px',  // Set a fixed width for a smaller button
    margin: '5px auto',  // Adjusted margin to keep the button centered
    fontSize: '12px',  // Optional: reduce font size for a smaller button appearance
  };




  // Add a hover effect for the button
  buttonStyle[':hover'] = {
    backgroundColor: '#0056b3'
  };

  const size_chat_link = {
    color: 'red',
    cursor: 'pointer'
  }

  // Function to toggle modal visibility
  const toggleModal = (e) => {
    if (e) {
      e.stopPropagation(); // Prevent any Bootstrap JS event
    }
    setIsModalOpen(!isModalOpen);
  };
  const thumbnailRef = useRef(null);
  const [reloadKey, setReloadKey] = useState(0);
  const [relatedProducts, setRelatedProducts] = useState([]); // Assume this is your related products
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [attributeVariants, setAttributeVariants] = useState([]);
  const [currentPrice, setCurrentPrice] = useState(null);

  const [sizeStockStatus, setSizeStockStatus] = useState(null);
  const [activeTab, setActiveTab] = useState('description');

  function formatDateSuffix(date) {
    if (date > 3 && date < 21) return 'th'; // for dates like 4th, 13th, or 20th
    switch (date % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  }


  const checkDelivery = () => {
    // Assuming pinCode is the value obtained from your input field
    if (!pinCode) {
      alert('Please enter a pin code.');
      return;
    }

    // Check if pinCode is a number and exactly 6 digits long
    if (!/^\d{6}$/.test(pinCode.trim())) {
      alert('Pin code must be a 6-digit number.');
      return;
    }

    const deliveryAvailable = pinCodesData.some(pinData => pinData['DESTINATION PINCODE'] === pinCode.trim());

    if (deliveryAvailable) {
      const expectedDeliveryDate = new Date();
      expectedDeliveryDate.setDate(expectedDeliveryDate.getDate() + 5);

      // Format the date
      const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
      const day = expectedDeliveryDate.getDate();
      const monthIndex = expectedDeliveryDate.getMonth();
      const year = expectedDeliveryDate.getFullYear();
      const suffix = formatDateSuffix(day);

      const formattedDate = `${monthNames[monthIndex]} ${day}${suffix}, ${year}`;
      setDeliveryInfo({ available: true, date: formattedDate });
    } else {
      setDeliveryInfo({ available: false, date: null });
    }
  };

  useEffect(() => {
    // Reset delivery info when pin code changes
    setDeliveryInfo({ available: null, date: null });
  }, [pinCode]);


  // Assuming this is your complete description string

  // Splitting the completeDescription into two parts

  function shuffleArray(array) {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  }
  const renderStars = (rating) => {
    let stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <i key={i} className={`fa${i <= rating ? 's' : 'r'} fa-star`}></i>
      );
    }
    return stars;
  };
  // Fetch the relatedProducts somewhere in your code
  useEffect(() => {
    // Fetch related products here and update setRelatedProducts
  }, [reloadKey]);


  const handleProductClick = (productId) => {
    // Navigate to the new product page
    // You can use react-router's navigate or history.push here

    // Increment the key to force remount of "Similar Products" only
    setReloadKey(prevKey => prevKey + 1);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch product details
        const productResponse = await axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/product/${productId}`);

        if (productResponse.data && productResponse.data.product) {

          const productData = productResponse.data.product;
          setProduct(productData);
          document.title = productData.title.en || 'Product Details';



          // Fetch related products in the same category
          const categoryId = productData.category; // Assuming category is directly in productData
          const relatedProductsResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/productsByCategory/${categoryId}`
          );

          if (relatedProductsResponse.data.products) {
            const relatedProductsData = relatedProductsResponse.data.products
              .filter((relatedProduct) => relatedProduct._id !== productId)
              .map((relatedProduct) => {
                return {
                  image: relatedProduct.image[0], // Assuming image is an array
                  id: relatedProduct._id,
                  slug: relatedProduct.slug // Include the slug here

                }
              });
            setRelatedProductsData(relatedProductsData);
            const fullData = relatedProductsResponse.data.products.filter((relatedProduct) => relatedProduct._id !== productId);
    
            setFullRelatedProductsData(fullData);
          }
          if (relatedProductsResponse.data.products) {
            const relatedProductsData = relatedProductsResponse.data.products;

            // Extract the _id of related products (excluding the current product)
            const relatedProductIds = relatedProductsData
              .filter((relatedProduct) => relatedProduct._id !== productId)
              .map((relatedProduct) => relatedProduct._id);

            // Fetch attributes of all related products
            const attributeTitles = []; // Initialize an empty array to collect attribute titles

            const promises = relatedProductIds.map(async (relatedProductId) => {
              try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/product/${relatedProductId}`);
                const data = await response.json();

                if (data && data.attr && typeof data.attr === 'object') {
                  Object.keys(data.attr).forEach((key) => {
                    const attrTitle = data.attr[key].attr_title;
                    if (attrTitle) {
                      attributeTitles.push(attrTitle);
                    }
                  });
                }
              } catch (error) {
                console.error("Error fetching product details:", error);
              }
            });

            // Wait for all attribute fetch requests to complete
            await Promise.all(promises);

            // Now you have all the attribute titles in the `attributeTitles` array
            setAttributeTitles(attributeTitles)
          }
        }
      } catch (error) {
        console.error("Error in fetching data:", error);
      }
    };

    fetchData();
  }, [productId, id]);



  const handleMouseMove = (e) => {
    const thumbnailImage = document.querySelector('.thumbnail-image');
    if (!thumbnailImage) return;

    const rect = thumbnailImage.getBoundingClientRect();
    const x = ((e.pageX - window.scrollX - rect.left) / rect.width) * 100;
    const y = ((e.pageY - window.scrollY - rect.top) / rect.height) * 100;
    thumbnailImage.style.transform = `scale(2)`;
    thumbnailImage.style.transformOrigin = `${x}% ${y}%`;
  };


  const handleMouseLeave = () => {
    const thumbnailImage = document.querySelector('.thumbnail-image');
    if (thumbnailImage) {
      thumbnailImage.style.transform = 'scale(1)';
      thumbnailImage.style.transformOrigin = 'center center';
    }
  };







  //   useEffect(() => {
  //     setTimeout(() => {
  //     const thumbnail = thumbnailRef.current;
  //     if (!thumbnail) {
  //         return;
  //     }

  //     const mainImage = thumbnail.querySelector("img");
  //     if (!mainImage) {
  //         return;
  //     }

  //     const handleMouseMove = (e) => {
  //         const x = e.clientX - thumbnail.getBoundingClientRect().left;
  //         const y = e.clientY - thumbnail.getBoundingClientRect().top;
  //         const xPercent = x / thumbnail.clientWidth * 100;
  //         const yPercent = y / thumbnail.clientHeight * 100;
  //         mainImage.style.transformOrigin = `${xPercent}% ${yPercent}%`;
  //     };

  //     const handleMouseEnter = () => {
  //         mainImage.classList.add("zoomed-in");
  //     };

  //     const handleMouseLeave = () => {
  //         mainImage.classList.remove("zoomed-in");
  //     };

  //     thumbnail.addEventListener("mousemove", handleMouseMove);
  //     thumbnail.addEventListener("mouseenter", handleMouseEnter);
  //     thumbnail.addEventListener("mouseleave", handleMouseLeave);

  //     return () => {
  //         thumbnail.removeEventListener("mousemove", handleMouseMove);
  //         thumbnail.removeEventListener("mouseenter", handleMouseEnter);
  //         thumbnail.removeEventListener("mouseleave", handleMouseLeave);
  //     };
  //   }); // 500 milliseconds delay

  // }, []);  



  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/product/${productId}`);
        setProduct(response.data.product);
        if (response.data.product?.image?.[0]) {
          setSelectedImage(response.data.product.image[0]);
        }

        // Calculate average rating
        const reviews = response.data.product.reviews || [];
        const totalRating = reviews.reduce((acc, review) => acc + review.rating, 0);
        const avgRating = reviews.length > 0 ? (totalRating / reviews.length) : 0;
        setAverageRating(avgRating);
        setReviewCount(reviews.length);

        // ... other logic to handle the response
      } catch (error) {
        console.error("Error in fetching product data:", error);
      }
    };



    const fetchWishlistStatus = async () => {
      try {
        const wishlistResponse = await axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/isProductInWishlist/${productId}`, {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
        });

        if (wishlistResponse.data.success) {
          setIsAddedToWishlist(wishlistResponse.data.inWishlist);
        }
      } catch (error) {
        console.error("Error in fetching wishlist status:", error);
      }
    };

    fetchProductDetails();
    fetchWishlistStatus();
  }, [productId]);





  function addProductToCart(productId, selectedSize) {

    const token = localStorage.getItem('token');
    if (!selectedSize) {
      return; // Prevent further execution
    }

    // If the user is not logged in, redirect them to the login page
    if (!token) {
      // Save the current page URL before redirecting
      const currentRoute = window.location.pathname; // Remove the '#' symbol
      localStorage.setItem('redirectAfterLogin', currentRoute);
      navigate('/signin'); // Redirect to login page
      return;
    }

    // Prepare the payload to include the selected size
    const payload = {
      size: selectedSize

    };

    // Try to add the product to the cart
    axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/addToCart/${productId}`, payload, {
      headers: { 'Authorization': `Bearer ${token}` }
    })
      .then(response => {
        if (response.data.success) {
          toast.success("Product added to cart!"); // This will show a success toast
        } else {
          // In case there's a specific message from the server (like "Product already in cart")
          toast.warn(response.data.message);  // This will show a warning toast
        }
      })
      .catch(error => {
        if (error.response && error.response.data.message) {
          if (error.response.data.message === "Product out of stock") {
            toast.warn("🚫 Product out of stock! Try another item."); // A more expressive warning
          } else {
            toast.error(error.response.data.message); // Existing error handling
          }
        } else {
          toast.error("An error occurred. Please try again later."); // General error message
          console.error("Error adding to cart:", error);
        }
      });
  }

  const [productDetails, setProductDetails] = useState(null);
  const [attributeDetails, setAttributeDetails] = useState(null);
  const [attributeNames, setAttributeNames] = useState([]);
  let descriptionString = '';
  let productHighlightsString = '';

  if (productDetails && productDetails.description && productDetails.description.en) {
    const splitIndex = productDetails.description.en.indexOf('Highlights:');
    if (splitIndex !== -1) {
      // If 'Highlights:' is found in the description
      descriptionString = productDetails.description.en.substring(0, splitIndex).trim();
      productHighlightsString = productDetails.description.en.substring(splitIndex).trim();
    } else {
      // If 'Highlights:' is not found in the description
      descriptionString = productDetails.description.en;
      productHighlightsString = ''; // Set to empty if 'Highlights:' is not found
    }
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/product/${productId}`)
      .then((response) => response.json())
      .then((data) => {
        setProductDetails(data.product);

        if (data.variants) {
          const firstAttrTitle = Object.keys(data.variants)[0];
          setAttributeVariants(data.variants[firstAttrTitle]);
        }

      })
      .catch((error) => {
        console.error("Error fetching product details:", error);
      });
  }, [productId]);

  const handleSizeClick = (variant) => {
    if (selectedSize === variant.attr_name) {
      setSelectedSize(null);  // Deselect the size
      setCurrentPrice(null);  // Reset to default price
      setSizeStockStatus(null); // Reset stock status when size is deselected
    } else {
      setSelectedSize(variant.attr_name);
      setCurrentPrice(variant.price);
      setSizeStockStatus(variant.quantity > 0 ? "In stock" : "Out of stock");
    }
  };




  const [selectedSize, setSelectedSize] = useState(null); // Store related products





  function toggleWishlist() {
    const token = localStorage.getItem('token');

    if (!token) {
      // Save the current page URL before redirecting
      localStorage.setItem('redirectAfterLogin', window.location.pathname);
      navigate('/signin'); // Redirect to login page
      return;
    }
    if (isAddedToWishlist) {
      axios.delete(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/removewishlist/${productId}`, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
      })
        .then(response => {
          if (response.data.success) {
            setIsAddedToWishlist(false);
            toast.success("Product removed from wishlist!"); // This will show a success toast
          } else if (response.data.message === "Product already in wishlist") {
            toast.warn(response.data.message);  // This will show a warning toast
          }
        })
        .catch(error => {
          console.error("Error removing from wishlist:", error);
        });
    } else {
      axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/addToWishlist/${productId}`, {}, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
      })
        .then(response => {
          if (response.data.success) {
            setIsAddedToWishlist(true);
            toast.success("Product added to wishlist!"); // This will show a success toast
          } else if (response.data.message === "Product already in wishlist") {
            toast.warn(response.data.message);  // This will show a warning toast
          }
        })
        .catch(error => {
          console.error("Error adding to wishlist:", error);
        });
    }
  }




  if (!productDetails) {
    return false;
  }
  

  // Sample size chart data
const sizeChartData = [
  { size: 'S', CM: '60-65', INCHES: '24-26' },
  { size: 'M', CM: '70-75', INCHES: '28-30' },
  { size: 'L', CM: '80-85', INCHES: '32-34' },
  { size: 'XL', CM: '90-95', INCHES: '36-38' },
  { size: '2XL', CM: '100-105', INCHES: '40-42' },
  { size: '3XL', CM: '110-115', INCHES: '44-46' },

];
const SizeChartPopup = ({ isOpen, onClose }) => {
  if (!isOpen) return null;
  const modalStyle_SizeChat = {
    display: 'flex', // Make it a flexbox for centering
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    zIndex: 1,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    'z-index': '2',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  };
  
  const modalContentStyle_SizeChat = {
    position: 'relative', // This is important for the close button positioning
    backgroundColor: '#fefefe',
    padding: '20px',
    border: '1px solid #888',
    borderRadius: '10px',
    width: '80%',
    maxWidth: '500px',
  };
  
  const closeButtonStyle_SizeChat = {
    color: '#aaa',
    fontSize: '30px',
    fontWeight: 'bold',
    position: 'absolute',
    top: '10px',
    right: '20px',
    cursor: 'pointer',
  };

  // New styles for the table with alternating row colors
const tableStyle = {
  width: '100%',
  borderCollapse: 'collapse',
  marginTop: '20px',
};

const tableHeaderStyle = {
  backgroundColor: '#f2f2f2',
  fontWeight: 'bold',
};

const tableRowStyle = {
  padding: '10px',
  border: '1px solid #ddd',
  textAlign: 'left',
};
// Function to dynamically set alternating background colors
const getRowStyle = (index) => {
  return {
    backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ffffff',
    padding: '10px',
  border: '1px solid #ddd',
  textAlign: 'left',
  };
};
  return (
    <div style={modalStyle_SizeChat}>
      <div style={modalContentStyle_SizeChat}>
        <span style={closeButtonStyle_SizeChat} onClick={onClose}>&times;</span>
        <h2>Size Chart</h2>
        <table style={tableStyle}>
          <thead>
            <tr>
              <th style={tableHeaderStyle}>SIZE</th>
              <th style={tableHeaderStyle}>CM</th>
              <th style={tableHeaderStyle}>INCHES</th>
            </tr>
          </thead>
            <tbody>
              {sizeChartData.map((item, index) => (
                <tr key={index}  style={getRowStyle(index)}>
                  <td>{item.size}</td>
                  <td>{item.CM}</td>
                  <td>{item.INCHES}</td>
                </tr>
              ))}
            </tbody>
            </table>
      </div>
    </div>
  );
};

  return (
    <>
<Helmet>
            <title>{product.title.en}</title>
            <meta name="description" content={product.description.en} />
            <meta property="og:title" content={product.title.en} />
            <meta property="og:description" content={product.description.en} />
            {/* Add more meta tags as needed */}
          </Helmet>



      {product && product._id && (







        <div class="axil-single-product-area  pb--0 bg-color-white" style={{ padding: '45px 0px' }}>
          <div class="single-product-thumb mb--40">
            <div class="container">
              <div class="row">
                <div class="col-lg-7 mb--10">
                  <div class="row">
                    <div class="col-lg-10 order-lg-2">
                      <div class="single-product-thumbnail-wrap zoom-gallery">
                        <div class="single-product-thumbnail product-large-thumbnail-3 axil-product" style={{ display: 'grid', gridTemplateColumns: '3fr 0fr' }}>
                          <div className="thumbnail-container" onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
                            {loading ? (
                              <Skeleton height={600} width={'100%'} count={1} className="custom-skeleton" />
                            ) : (
                              <img
                                src={selectedImage || "default-image.jpg"}
                                alt={productDetails?.title?.en || "Error"}
                                className="thumbnail-image"
                              />
                            )}
                          </div>


                        </div>

                      </div>
                    </div>
                    <div className="product-small-thumb-3 small-thumb-wrapper" style={{ width: 'max-content' }} >
                      {product?.image?.map((imgSrc, index) => (
                        <div
                          key={index}
                          className="small-thumb-img"
                          onClick={() => setSelectedImage(imgSrc)}
                        >
                          {loading ? (
                            // Display the skeleton image with loading animation
                            <Skeleton
                              height={80}
                              width={80}
                              count={1}
                              className="custom-skeleton"
                            />
                          ) : (
                            <img src={imgSrc} alt={`Thumbnail ${index}`} />
                          )}
                        </div>
                      ))}
                    </div>

                  </div>
                </div>
                <div class="col-lg-5 mb--40">
                  <div class="single-product-content">
                    <div class="inner">
                      {loading ? (
                        // Display the skeleton image with loading animation
                        <Skeleton
                          height={20}
                          width={'100%'}
                          count={1}
                          className="custom-skeleton"
                        />
                      ) : (
                        <div className="product-rating">
                          <div className="star-rating">
                            {renderStars(averageRating)}
                          </div>
                          <div className="review-link">
                            <a href="#">
                              (<span>{reviewCount}</span> customer reviews)
                            </a>
                          </div>
                        </div>

                      )}

                      {loading ? (
                        // Display the skeleton image with loading animation
                        <Skeleton
                          height={20}
                          width={'100%'}
                          count={1}
                          className="custom-skeleton"
                        />
                      ) : (
                        <h2 class="product-title">
                          {productDetails &&
                            productDetails.title &&
                            productDetails.title?.en}
                        </h2>

                      )}


                      {loading ? (
                        // Display the skeleton image with loading animation
                        <Skeleton
                          height={20}
                          width={'50%'}
                          count={1}
                          className="custom-skeleton"
                        />
                      ) : (
                        <span className="price-amount text">

                          <strong style={{ fontSize: '20px' }}>
                            Rs.{currentPrice || product.prices.price}
                          </strong>

                          {product.prices.discount > 0 && product.prices.originalPrice > (currentPrice || product.prices.price) && (
                            <>
                              <del className="woocommerce-Price-amount amount">
                                Rs. {product.prices.originalPrice}
                              </del>
                              &nbsp;
                            </>
                          )}
                          &nbsp;  <a className="mini-text">(Inclusive Of All Taxes)</a>{" "}
                        </span>
                      )}

                      {loading ? (
                        // Display the skeleton image with loading animation
                        <Skeleton
                          height={20}
                          width={'50%'}
                          count={1}
                          className="custom-skeleton"
                        />
                      ) : (
                        <strong id="cs-fnt" className="py-3">
                          Availability :{" "}
                          <a className={(sizeStockStatus || (productDetails.stock > 0 ? "In stock" : "Out of stock")) === "In stock" ? "text-in-stock" : "text-out-of-stock"}>
                            {sizeStockStatus || (productDetails.stock > 0 ? "In stock" : "Out of stock")}
                          </a>
                        </strong>


                      )}

                      <div className="row">
                        <div className="col-md-12 py-3">
                          {loading ? (
                            // Display the skeleton image with loading animation
                            <Skeleton
                              height={20}
                              width={'30%'}

                              className="custom-skeleton"
                            />
                          ) : (
                            <strong id="cs-fnt" className="mt--10">
                              Color :
                            </strong>

                          )}
                        </div>
                      </div>
                      <div class="product-variations-wrapper">
                        <div class="product-variation">

                          <div class="color-variant-wrapper">


                            {(showAll ? relatedProductsData : relatedProductsData.slice(0, 5)).map((product, index) => (
                              <div key={index} style={{ marginRight: '10px' }}>
                                <a
                                  href={`/products/${product.slug}/${product.id}`}
                                  onClick={(e) => {
                                    e.preventDefault(); // Prevent default link navigation
                                    navigate(`/products/${product.slug}/${product.id}`);
                                    //window.location.reload(); // Reload the page
                                  }}
                                  style={{ width: '100px', height: '100px' }}
                                >

                                  {loading ? (
                                    // Display the skeleton image with loading animation
                                    <Skeleton
                                      height={60}
                                      width={60}

                                      className="custom-skeleton"
                                    />
                                  ) : (
                                    <img
                                      src={product.image}
                                      alt={`Related Product ${index + 1}`}
                                      width="100%"
                                    />
                                  )}
                                </a>
                              </div>
                            ))}

                            {!showAll && relatedProductsData.length > 4 && (
                              <button
                                id="show-all-btn"
                                onClick={() => setShowAll(true)}
                              >
                                Show All
                              </button>
                            )}
                          </div>




                        </div>
                        <div className="row">
                          <div className="col-md-12 pt-20">
                            {loading ? (
                              // Display the skeleton image with loading animation
                              <Skeleton
                                height={20}
                                width={'30%'}

                                className="custom-skeleton"
                              />
                            ) : (
                              <strong id="cs-fnt" className="mt--10">
                                Size : 
                               <span
                                  style={size_chat_link}
                                  onClick={openSizeModal}
                                >
                                
                                  Size Chart
                                </span>
                                
                              </strong>
                              
                            )}

                            {/* Modal Component */}
      <SizeChartPopup isOpen={isSizeModalOpen} onClose={closeSizeModal} />
                          </div>
                        </div>
                        {attributeVariants
                          .filter(variant => variant.quantity > 0)  // Filter out sizes with a quantity of 0
                          .map((variant, index) => (
                            <button
                              key={index}
                              className={`size-buttons-size-button size-buttons-size-button-default ${selectedSize === variant.attr_name ? 'selected' : ''}`}
                              onClick={() => handleSizeClick(variant)}
                            >
                              <span className="size-buttons-size-strike-hide"></span>
                              <p className="size-buttons-unified-size">{variant.attr_name}</p>
                            </button>
                          ))}





                      </div>

                      <div class="product-action-wrapper d-flex-center mv-left mt--10" style={{ marginBottom: '5px' }}>
                        <ul class="product-action d-flex-center mb--0" style={{ marginLeft: '-10px' }}>
                          <li class="cart">
                            <div
                              onClick={() => {
                                if (!selectedSize) {
                                  toast.warn("Please select a size.");  // Display a warning toast
                                }
                              }}
                            >
                              <button style={{ padding: '10px 50px', cursor: "pointer" }}
                                onClick={() => {
                                  if (selectedSize) {
                                    addProductToCart(productDetails._id, selectedSize);  // Pass selectedSize as a second argument
                                  } else {
                                    toast.warn("Please select a size.");  // Display a warning toast
                                  }
                                }}
                                disabled={!selectedSize}
                                className={`axil-btn single-add-btn`}
                              >
                                Add to Cart <i class="flaticon-shopping-cart new-cart" style={{ fontSize: '16px', fontWeight: '600', cursor: "pointer" }}></i>
                              </button>

                            </div>

                          </li>
                          <li class="wishlist">
                            <button onClick={toggleWishlist} className="axil-btn wishlist-btn">
                              {isAddedToWishlist ? <MdFavorite size={24} color="black" /> : <MdFavoriteBorder size={24} color="black" />}
                            </button>
                          </li>
                        </ul>

                      </div>

                      <div style={containerStyle}>
                        <input
                          type="text"
                          maxLength="6"
                          placeholder="Enter Pin Code"
                          value={pinCode}
                          onChange={(e) => setPinCode(e.target.value)}
                          style={inputStyle}
                        />
                        <button onClick={checkDelivery} style={buttonStyle}>Check Delivery</button>
                        {pinCode && deliveryInfo.available !== null && (
                          <div style={deliveryInfoStyle}>
                            <p style={{ color: deliveryInfo.available ? 'green' : 'red' }}>
                              {deliveryInfo.available
                                ? `Delivery available. Expected delivery by ${deliveryInfo.date}`
                                : 'Delivery not available for this pin code.'}
                            </p>
                          </div>
                        )}
                      </div>


                      <div className="mini-box mt-5">
                        <div className="row">
                          <div className="col-md-12">


                            <strong id="cs-fnt" className="py-3">
                              Easy Exchange Policy
                            </strong>
                            <span onClick={toggleModal} className="py-3">
                              <u className="cm-point">Know More</u>
                            </span>



                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-12 mb--30">
                            {/* <div class="single-desc mt--10">
  <h5 style={{ marginBottom: "5px" }}>Description</h5>
  <p>
    {productDetails && productDetails.description && (
      isDescriptionExpanded 
        ? productDetails.description.en 
        : `${productDetails.description.en.substring(0, 400)}... `
    )}
    {productDetails && productDetails.description && productDetails.description.en.length > 400 && (
      <a 
        href="#" 
        onClick={(e) => {
          e.preventDefault();
          setDescriptionExpanded(!isDescriptionExpanded);
        }}
        style={{
          color: 'blue', // Set color for the anchor
          cursor: 'pointer', // Make it look clickable
        }}>
        {isDescriptionExpanded ? 'Less' : 'More'}
      </a>
    )}
  </p>
</div>
 */}

                            <div className="woocommerce-tabs" style={{ paddingTop: '20px' }}>
                              <ul className="nav tabs" id="myTab" role="tablist" style={{ marginBottom: '15px' }}>
                                <li className="nav-item" role="presentation">
                                  <button
                                    className={`nav-link ${activeTab === 'description' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('description')}
                                    style={{
                                      backgroundColor: activeTab === 'description' ? '#F04F23' : 'transparent',
                                      color: activeTab === 'description' ? 'white' : 'black', // Optional: change text color for better visibility
                                      border: 'none', // Optional: remove button borders
                                      borderRadius: '4px', // Optional: round the corners
                                      padding: '5px 10px' // Optional: adjust padding to your preference
                                    }}
                                  >
                                    Description
                                  </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                  <button
                                    className={`nav-link ${activeTab === 'productHighlights' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('productHighlights')}
                                    style={{
                                      backgroundColor: activeTab === 'productHighlights' ? '#F04F23' : 'transparent',
                                      color: activeTab === 'productHighlights' ? 'white' : 'black', // Optional: change text color for better visibility
                                      border: 'none', // Optional: remove button borders
                                      borderRadius: '4px', // Optional: round the corners
                                      padding: '5px 10px' // Optional: adjust padding to your preference
                                    }}
                                  >
                                    Product Highlights
                                  </button>
                                </li>
                              </ul>

                              <div className="tab-content" id="myTabContent">
                                <div className={`tab-pane fade ${activeTab === 'description' ? 'show active' : ''}`} id="description" role="tabpanel">
                                  <div className="product-desc-wrapper">
                                    <div className="row">
                                      <div className="col-lg-12 mb--30">
                                        <div className="single-desc">
                                          <p>{descriptionString}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className={`tab-pane fade ${activeTab === 'productHighlights' ? 'show active' : ''}`} id="productHighlights" role="tabpanel">
                                  <div className="reviews-wrapper">
                                    <div className="row">
                                      <div className="col-lg-12 mb--40">
                                        <div className="axil-comment-area">
                                          {productHighlightsString.split('\n').map((line, index) => (
                                            <React.Fragment key={index}>
                                              <span>{line}</span>
                                              <br />
                                            </React.Fragment>
                                          ))}
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>


                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="woocommerce-tabs wc-tabs-wrapper bg-vista-white">
            <div class="container">

              <div class="tab-content" id="myTabContent">


                <div

                >
                  {product && product.slug && (

                    <div className="reviews-wrapper">
                      <div className="row">
                        <div className="col-lg-6 mb--40">
                          <div className="axil-comment-area pro-desc-commnet-area">
                            <h5 className="title">Reviews</h5>
                            <ul className="comment-list">
                              {product.reviews && product.reviews.length > 0 ? (
                                product.reviews.map((review, index) => (
                                  <li className="comment" key={index}>
                                    <div className="comment-body">
                                      <div className="single-comment">
                                        <div className="comment-inner">
                                          <h6 className="commenter">
                                            {review.name}
                                            <span className="commenter-rating">
                                              {renderStars(review.rating)}
                                            </span>
                                          </h6>
                                          <div className="comment-text">
                                            <p>{review.comment}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                ))
                              ) : (
                                <p>No reviews yet.</p>
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    class="tab-pane fade show active"
                    id="description"
                    role="tabpanel"
                    aria-labelledby="description-tab"
                  >
                    <div class="product-desc-wrapper">


                      <div class="row">
                        <div class="col-lg-12">
                          <ul class="pro-des-features">
                            <li class="single-features">
                              <div class="icon">
                                <img
                                  src="/assets/images/product/product-thumb/icon-3.png"
                                  alt="icon"
                                />
                              </div>
                              Easy Exchange
                            </li>
                            <li class="single-features">
                              <div class="icon">
                                <img
                                  src="/assets/images/product/product-thumb/icon-2.png"
                                  alt="icon"
                                />
                              </div>
                              Quality Product
                            </li>
                            <li class="single-features">
                              <div class="icon">
                                <img
                                  src="/assets/images/product/product-thumb/icon-3.png"
                                  alt="icon"
                                />
                              </div>
                              Safe Delivery
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="similar-products-section">
            {fullRelatedProductsData.length > 0 && (
              <>
              {console.log(fullRelatedProductsData)}
                <h2 className="text-center">Similar Products</h2>
                <SimilarProductCarousel products={fullRelatedProductsData} />

              </>
            )}
          </div>


          {isModalOpen && (

            <div className={`modal fade right-to-left ${isModalOpen ? 'show' : ''}`}
              id="customModal"
              tabIndex="-1"
              aria-labelledby="customModalLabel"
              aria-hidden={!isModalOpen}
              style={{
                display: isModalOpen ? 'block' : 'none', backgroundColor: 'transparent'  // Set the background color to transparent
              }}>  <div class="modal-dialog modal-dialog-scrollable modal-dialog-end">
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-end" onClick={e => e.stopPropagation()}> {/* Prevents click inside from closing modal */}
                  <div className="modal-content" style={{ border: '1px solid white' }}>
                    <div className="modal-header">
                      <h5 className="modal-title">Exchange Policy</h5>
                      <button type="button" className="btn-close" aria-label="Close" onClick={toggleModal}></button>
                    </div>
                    <div class="modal-body"><p><span class="text-custm-bold"> Timeframe: </span>Dazzle Sportswear allows exchange of the purchased product(s) within 24-48 hours from the date of receiving the parcel.</p><p><span class="text-custm-bold"> Condition of the item: </span>The only criteria for the product(s) to be exchanged is that the tags must be intact and free from damage. Please note that it should not have any signs of being worn.</p><p><span class="text-custm-bold"> Proof for purchase: </span> Purchase receipt must be attached as a proof when raising a request for exchange. This process usually takes 10-15 working days.</p><p><span class="text-custm-bold"> Method of exchange:  </span> On the basis of availability, the store might offer an exchange with a different colour, size or style of the requested item.</p></div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SingleProductDetails;