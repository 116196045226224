import React from 'react'
import { Link } from 'react-router-dom';
import img3 from '../assests/dazzle-images/2.png'
import img4 from '../assests/dazzle-images/3.png'

import img1 from '../assests/dazzle-images/4.png'
import img2 from '../assests/dazzle-images/5.png'
const CategoryComponent = () => {
  return (
    <>
         <div className="axil-poster-countdown  ">
            <div className="container">
                <div className="poster-countdown-wrap bg-yellow ">
                   <div className="row ">
                    <div className="col-md-6 ">
                         <div className="row">
                    
                        <div className="col-xl-7 col-lg-6">
                            <div className="poster-countdown-thumbnail">
                                <img src={img1} className="poster-img custom-image"  alt="Poster Product"/> 
                              
                            </div>
                        </div>
                            <div className="col-xl-5 col-lg-6">
                            <div className="poster-countdown-content">
                                <div className="section-title-wrapper">
                                    <span className="title-highlighter highlighter-secondary mt--10"> <i className="fal fa-headphones-alt"></i> Don’t Miss!!</span>
                                    <h5 className="hm-h5">Newly Arrived</h5>
                                    <p className='mini-text'>Wear style proudly
Select T-Shirts with choice in various sleeve and patterns</p>
                                </div>
                              
                                <Link to={"/tshirts?type=Matty%20Trendy%20T-shirt"} className="axil-btn  check-out-btn shop-now-btn">SHOP NOW</Link>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-6 ">
                         <div className="row">
                    
                        <div className="col-xl-7 col-lg-6">
                            <div className="poster-countdown-thumbnail">
                                <img src={img2} className="poster-img custom-image"  alt="Poster Product" />
                              
                            </div>
                        </div>
                            <div className="col-xl-5 col-lg-6">
                            <div className="poster-countdown-content">
                                <div className="section-title-wrapper">
                                    <span className="title-highlighter highlighter-secondary mt--10"> <i className="fal fa-headphones-alt"></i> Don’t Miss!!</span>
                                    <h5 className="hm-h5">Limited Edition</h5>
                                    <p className='mini-text'>Athletic and Daily Bottom Wear
Bottom wear that redefines the definition of trend</p>
                                </div>
                              
                                <Link to={"/lowers?type=PC%20Jogger"} className="axil-btn  check-out-btn shop-now-btn">SHOP NOW</Link>
                            </div>
                        </div>
                    </div>
                    </div>
                   </div>
                </div>
            </div>
        </div>






        <div className="axil-poster-countdown ">
            <div className="container">
                <div className="poster-countdown-wrap bg-yellow">
                   <div className="row">
                    <div className="col-md-6">
                         <div className="row">
                    
                        <div className="col-xl-7 col-lg-6">
                            <div className="poster-countdown-thumbnail">
                                <img src={img3} className="poster-img custom-image"  alt="Poster Product" />
                              
                            </div>
                        </div>
                            <div className="col-xl-5 col-lg-6">
                            <div className="poster-countdown-content">
                                <div className="section-title-wrapper">
                                    <span className="title-highlighter highlighter-secondary mt--10"> <i className="fal fa-headphones-alt"></i> Don’t Miss!!</span>
                                    <h5 className="hm-h5">Trending Now</h5>
                                    <p className='mini-text'>Classy lowers with ultimate comfort
This is what your wardrobe is missing right now</p>
                                </div>
                              
                                <Link to={"/lowers?type=Younger"} className="axil-btn  check-out-btn shop-now-btn">SHOP NOW</Link>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-6">
                         <div className="row">
                    
                        <div className="col-xl-7 col-lg-6">
                            <div className="poster-countdown-thumbnail">
                                <img src={img4} className="poster-img custom-image"  alt="Poster Product" />
                              
                            </div>
                        </div>
                            <div className="col-xl-5 col-lg-6">
                            <div className="poster-countdown-content">
                                <div className="section-title-wrapper">
                                    <span className="title-highlighter highlighter-secondary mt--10"> <i className="fal fa-headphones-alt"></i> Don’t Miss!!</span>
                                    <h5 className="hm-h5">MUST HAVE</h5>
                                    <p className='mini-text'>Hoodies and Other Essentials
Come dive into our range of various other products</p>
                                </div>
                              
                                <Link to={"/more"} className="axil-btn  check-out-btn shop-now-btn">SHOP NOW</Link>
                            </div>
                        </div>
                    </div>
                    </div>
                   </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default CategoryComponent
