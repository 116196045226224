import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';




const Navbar = () => {

  useEffect(() => {
    // Mobile menu activation
    const handleMobileMenu = () => {
      const menuItemsWithChildren = document.querySelectorAll('.menu-item-has-children > a');
      const megamenuLinks = document.querySelectorAll('.nav-link.has-megamenu');

      menuItemsWithChildren.forEach((item) => {
        item.addEventListener('click', (e) => {
         

          const targetParent = item.closest('.header-main-nav');
          const target = item.nextElementSibling;

          if (targetParent.classList.contains('open')) {
            target.style.display = target.style.display === 'none' ? 'block' : 'none';
            targetParent.classList.toggle('open');
          }
        });
      });

      megamenuLinks.forEach((link) => {
        link.addEventListener('click', (e) => {
          e.preventDefault();

          const targetElm = link.nextElementSibling;
          targetElm.style.display = targetElm.style.display === 'none' ? 'block' : 'none';
        });
      });

      // Mobile Sidemenu Class Add
      function resizeClassAdd() {
        if (window.matchMedia('(max-width: 1199px)').matches) {
          const departmentTitles = document.querySelectorAll('.department-title');
          const departmentMegamenus = document.querySelectorAll('.department-megamenu');

          departmentTitles.forEach((title) => {
            title.classList.add('department-side-menu');
          });

          departmentMegamenus.forEach((megamenu) => {
            megamenu.classList.add('megamenu-mobile-toggle');
          });
        } else {
          const departmentTitles = document.querySelectorAll('.department-title');
          const departmentMegamenus = document.querySelectorAll('.department-megamenu');

          departmentTitles.forEach((title) => {
            title.classList.remove('department-side-menu');
          });

          departmentMegamenus.forEach((megamenu) => {
            megamenu.classList.remove('megamenu-mobile-toggle');
            megamenu.style.display = 'none';
          });
        }
      }

      window.addEventListener('resize', resizeClassAdd);
      resizeClassAdd();
    };

    // Call the mobile menu activation function
    handleMobileMenu();
  }, []); // Empty dependency array means this effect runs once after initial render



  const [user, setUser] = useState(null);
  const [cart, setCart] = useState([]);
  const token = localStorage.getItem("token");

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const navigate = useNavigate();
  const [lastRequestTime, setLastRequestTime] = useState(null);




  const handleSearchSubmit = (e) => {
    e.preventDefault();

    if (searchQuery.trim().length < 3) {
      toast.warn('Enter at least 3 characters to search.');
      return;
    }

    axios
      .get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/search?query=${searchQuery}`)
      .then((response) => {
        const products = response.data.products;

        // Close the search modal
        setIsSearchModalOpen(false);

        // Navigate to search results page
        navigate('/search-results', { state: { products } });
      })
      .catch((error) => {
        console.error("Error fetching search results:", error);
      });
  }

  const handleSearchButtonClick = () => {
    setIsSearchModalOpen(true);
  }

  const handleSearchModalClose = () => {
    setIsSearchModalOpen(false);
  }

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearchSubmit(e);
    }
  }

  const cartCount = () => {
    if (!token) {
      setCart([]);
      return;
    }

    // Update the last request time
    setLastRequestTime(Date.now());

    axios
      .get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/getCart`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setCart(response.data.cart);
      })
      .catch((error) => {
        console.error("Error fetching cart:", error);
      });
  };

  const handleMouseEnter = () => {
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
  };
  const handleLogout = () => {
    localStorage.removeItem("token");
    setUser(null);

    toast.success("Logged out successfully.");
    setTimeout(() => {
      window.location.reload();
  }, 3000);

  };
  useEffect(() => {
    if (!token) return;

    const intervalId = setInterval(() => {
        cartCount();
    }, 2000); // 1 second

    return () => clearInterval(intervalId); // Cleanup on component unmount
}, [token]);


  return (
    <>
      <header className="header axil-header header-style-5">
        <div id="axil-sticky-placeholder"></div>
        <div className="axil-mainmenu">
          <div className="container">
            <div className="header-navbar">
              <div className="header-brand">
                <Link to={"/"} className="logo logo-dark">
                  <img src={"https://dazzlesportswear.com/images/logo.png"} alt="Site Logo" />
                </Link>
              </div>
              <div className="header-main-nav">
                <nav className="mainmenu-nav">
                  <button className="mobile-close-btn mobile-nav-toggler">
                    <i className="fas fa-times"></i>
                  </button>
                  <div className="mobile-nav-brand">
                    <Link to={"/"} className="logo">
                      <img src={"https://dazzlesportswear.com/images/logo.png"} alt="Site Logo" />
                    </Link>
                  </div>
                  <ul className="mainmenu">
                 
                    <li className="menu-item-has-children">
                      <Link to={"/tshirtscollections"}>T-Shirts</Link>
                      <ul className="axil-submenu four-columns " ></ul>
                    </li>
                    <li className="menu-item-has-children">
                      <Link to="/lowerscollections">Lowers</Link>
                      <ul className="axil-submenu four-columns " ></ul>
                    </li>
                    <li className="menu-item-has-children">
                      <Link to="/shortscollections">Shorts</Link>
                      <ul className="axil-submenu four-columns " ></ul>
                    </li>

                    <li className="menu-item-has-children">
                      <Link to={"/more"}>More</Link>
                      <ul className="axil-submenu four-columns " ></ul>
                    </li>
                    <li className="menu-item-has-children">
                      <Link to={"/blog"}>Blog</Link>
                      <ul className="axil-submenu four-columns " ></ul>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="header-action">
                <ul className="action-list">
                <li className="axil-search d-xl-block d-block">
                    
            


      
                <button onClick={handleSearchButtonClick}   className="icon wooc-btn-search">
                <i className="flaticon-magnifying-glass"></i>
        </button>
</li>

                  <li
                    className="my-account"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <a>
                      <i className="flaticon-person"></i>
                    </a>

                    {token ? (
                      <div
                        className={`my-account-dropdown ${
                          isDropdownOpen ? "open" : ""
                        }`}
                      >
                        <span>QUICKLINKS</span>
                        <ul>
                          <li>
                            <Link to={"/myaccount"}>My Account</Link>
                          </li>
                          <li>
                            <Link to={"/cart"}>Cart</Link>
                          </li>
                        </ul>
                        <div className="login-btn">
                          <button
                            onClick={handleLogout}
                            className="axil-btn btn-bg-primary"
                          >
                            Logout
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`my-account-dropdown ${
                          isDropdownOpen ? "open" : ""
                        }`}
                      >
                        <div className="login-btn">
                          <Link
                            to={"/signin"}
                            className="axil-btn btn-bg-primary"
                          >
                            Login
                          </Link>
                        </div>
                        <div className="reg-footer text-center">
                          No account yet?{" "}
                          <Link to={"/signup"} className="btn-link">
                            REGISTER HERE.
                          </Link>
                        </div>
                      </div>
                    )}
                  </li>
                  <li className="wishlist">
                    <Link to="/wishlist">
                      <i className="flaticon-heart"></i>
                    </Link>
                  </li>
                  <li className="shopping-cart">
                    <Link to={"/cart"} className="cart-dropdown-btn">
                      <span className="cart-count">{cart.length}</span>
                      <i className="flaticon-shopping-cart"></i>
                    </Link>
                  </li>

                  <li className="axil-mobile-toggle">
                    <button className="menu-btn mobile-nav-toggler">
                      <i className="flaticon-menu-2"></i>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className={`header-search-modal ${isSearchModalOpen ? 'open' : ''}`} id="header-search-modal">
        <button className="card-close sidebar-close" onClick={handleSearchModalClose}><i className="fas fa-times"></i></button>
     
        <div className="header-search-wrap">
            <div className="card-header">
                <form  onSubmit={handleSearchSubmit}>
                    <div className="input-group">
                   
        <input 
            type="text" 
            className="form-control" 
            placeholder="Ex: Tshirts, Lowers, Shorts"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)} 
            name="prod-search" id="prod-search" onKeyPress={handleInputKeyPress}
        /> 
                               <button type="submit" className="axil-btn btn-bg-primary"><i className="far fa-search"></i></button>
                        <button className="search-btn-coustm"   onClick={handleSearchSubmit}>Search</button>
                      
                    </div>
                </form>
            </div>
           
        </div>
    </div>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        style={{ width: "max-content" }}
      />
    </>
  );
};

export default Navbar;
