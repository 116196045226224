import React from 'react'
import { Link } from 'react-router-dom';
import { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import metaData from './meta.json'; // Import the metadata JSON file
import 'react-toastify/dist/ReactToastify.css';
const Contact = () => {
    useEffect(() => {
        if (metaData && metaData[3] && metaData[3].components && metaData[3].components.Contact) {
          const { title, description } = metaData[3].components.Contact;
          document.title = title;
          const metaDescription = document.querySelector('meta[name="description"]');
          if (metaDescription) {
            metaDescription.setAttribute('content', description);
          }
        }
      }, []);
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        email: "",
        message: "",
      });
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Send the form data to the backend for email sending
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/sendEmail`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(formData),
            }
          );
    
          if (response.ok) {
            // Email sent successfully, show a success message or redirect as needed
            toast.success("Email sent successfully");
          } else {
            // Handle the error, show an error message, or redirect as needed
            toast.error("Email sending failed");
          }
        } catch (error) {
          console.error("Error:", error);
        }
      };
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
  return (
    <div>

<main className="main-wrapper">
        
       
        
        <div className="axil-contact-page-area axil-section-gap">
            <div className="container">
                <div className="axil-contact-page">
                    <div className="row row--30">
                        <div className="col-lg-8">
                            <div className="contact-form">
                                <h3 className="title mb--10">We would love to hear from you.</h3>
                                <p>If you have any feedback or are interested to work along, just leave us a message.</p>
                                <form onSubmit={handleSubmit} id="contact-form" method="POST" action="#" className="axil-contact-form">
                                    <div className="row row--10">
                                        <div className="col-lg-4">
                                            <div className="form-group">
                                                <label for="contact-name">Name <span>*</span></label>
                                                <input type="text" name="name" id="contact-name" value={formData.name} onChange={handleChange} />

                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="form-group">
                                                <label for="contact-phone">Phone <span>*</span></label>
                                                <input type="text" name="phone" id="contact-phone" value={formData.phone} onChange={handleChange} />
                                         </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="form-group">
                                                <label for="contact-email">E-mail <span>*</span></label>
                                                <input type="email" name="email" id="contact-email" value={formData.email} onChange={handleChange} />
                                        </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label for="contact-message">Your Message</label>
                                                <textarea name="message" id="contact-message" value={formData.message} onChange={handleChange} cols="1" rows="2"></textarea>                                                   </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group mb--0">
                                                <button name="submit" type="submit" id="submit" className="axil-btn btn-bg-primary">Send Message</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-4">
                        <div className="axil-footer-widget">
                        <h4 className="title mb--20">Location</h4>
                            <div className="inner">
                            <ul className="support-list-item">
                                    <li><Link to="mailto:support@dazzlesportswear.com"><i className="fal fa-envelope-open"></i>support@dazzlesportswear.com</Link></li>
                                    <li><Link to="tel:+919246828188"><i className="fal fa-phone"></i>+919246828188</Link></li>
                                   <li><i className="fal fa-map-marker-alt"></i>Dazzle Sports Wear Pvt Ltd.
                                                                                <br/><b>Add:</b> Plot No. 107, 107a, 108, Survey No. 509/1/2,
                                                                                <br/>Apparel Export Park, Gundlapochampally,
                                                                                <br/>Hyderabad - 500014,</li>
                                </ul>
                            </div>
                        </div>
                           
                            <div className="opening-hour">
                                <h4 className="title mb--20">Opening Hours:</h4>
                                <p>
                                   Monday - Saturday: 9:30 AM - 6:30 PM
                                    <br/> Sundays: Close
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
              
                <div className="axil-google-map-wrap axil-section-gap pb--0">
                    <div className="mapouter">
                        <div className="gmap_canvas">
                            <iframe width="1080" height="500" id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3803.8035578423887!2d78.46456827429658!3d17.564536997679323!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb8f1ff33ab5cb%3A0x7d65cedd9ce44e36!2sDazzle%20Sport%20Wear%20Private%20Ltd!5e0!3m2!1sen!2sin!4v1695707190847!5m2!1sen!2sin"></iframe>
                            
                        </div>
                    </div>
                </div>
              
            </div>
        </div>
       
    </main>

    </div>
  )
}

export default Contact