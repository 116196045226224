import React, { useState } from 'react';
import "./review.css";

const ReviewModal = ({ isOpen, closeModal, submitReview }) => {
  const [rating, setRating] = useState(0);
  const [notes, setNotes] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    if (rating === 0 || notes.trim() === '') {
      alert('Please provide a rating and some notes.');
      return;
    }

    setIsSubmitting(true);
    try {
      await submitReview({ rating, notes });
      closeModal();
    } catch (error) {
      console.error('Error submitting review:', error);
      // Handle error appropriately
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleModalClick = (e) => e.stopPropagation();

  return (
    isOpen && (
      <div className="modal-container" onClick={closeModal}>
        <div className="modal-content" onClick={handleModalClick}>
          <h2>Write a Review</h2>
          <div className="star-rating-container">
            {[1, 2, 3, 4, 5].map((star) => (
              <span
                key={star}
                className={star <= rating ? 'active-star' : 'inactive-star'}
                onClick={() => setRating(star)}
                aria-label={`Rate ${star} stars`}
              >
                &#9733;
              </span>
            ))}
          </div>
          <textarea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder="Your review here..."
            aria-label="Review notes"
          />
          
          
          
          <div
  style={{
    display: 'flex', // Enable flex container
    justifyContent: 'center', // Center horizontally
    marginTop: '20px' // Add some space above the button
  }}
><button 
          style={{
            padding: "5px 10px", // Smaller padding for a smaller button
            fontSize: "12px", // Smaller font size
            backgroundColor: "grey", 
            width:"70px",// Custom background color
            color: "white", // Text color
            border: "none", // Remove default border
            borderRadius: "5px", // Rounded corners
            cursor: "pointer",
            marginTop: "10px", // Cursor on hover
          }} onClick={handleSubmit} disabled={isSubmitting}>
            {isSubmitting ? 'Saving...' : 'Save'}
          </button></div>
          
          
          
          <div
  style={{
    display: 'flex', // Enable flex container
    justifyContent: 'center', // Center horizontally
    marginTop: '20px' // Add some space above the button
  }}
>
          <button  style={{
        padding: "5px 10px", // Smaller padding for a smaller button
        fontSize: "12px", // Smaller font size
        backgroundColor: "grey", 
        width:"70px",// Custom background color
        color: "white", // Text color
        border: "none", // Remove default border
        borderRadius: "5px", // Rounded corners
        cursor: "pointer",
        marginTop: "10px", // Cursor on hover
      }}onClick={closeModal}>Cancel</button>
      </div>
        </div>
      </div>
    )
  );
};

export default ReviewModal;
