import React from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "600px",
    padding: "20px",
    overflowY: "auto", // Enable vertical scrolling
    background: "white", // Set a solid background color
    zIndex: 1000 // Ensure the modal is on top of other content
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)", // Darken the background behind the modal
    zIndex: 999 // Ensure the overlay is below the modal but above other content

  

  },
};


Modal.setAppElement("#root"); // Set the root element for the modal

function OrderDetailsModal({ isOpen, closeModal, order }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Order Details Modal"
    >
      <h2>Order Details</h2>
      <div>
        <label>Title:</label>
        <span>{order.cart.map((item) => item.title.en)}</span>
      </div>
      <div>
        <label>Quantity:</label>
        <span>{order.cart.map((item) => item.quantity).join(", ")}</span>
      </div>
      <div>
        <label>Size:</label>
        <span>{order.cart.map((item) => item.size).join(", ")}</span>
      </div>
      <div>
        <label>Price:</label>
        <span>{order.cart.map((item) => item.price).join(", ")}</span>
      </div>
      <div>
        <label>Subtotal:</label>
        <span>{order.subTotal}</span>
      </div>
      <div>
        <label>Shipping Cost:</label>
        <span>{order.shippingCost}</span>
      </div>
      <div>
        <label>Discount (%):</label>
        <span>{order.discount}</span>
      </div>
      <div>
        <label>Shipping Option:</label>
        <span>{order.shippingOption}</span>
      </div>
      <div>
        <label>Payment Method:</label>
        <span>{order.paymentMethod}</span>
      </div>
      <div>
        <label>Status:</label>
        <span>{order.status}</span>
      </div>
      <div>
        <label>Invoice:</label>
        <span>{order.invoice}</span>
      </div>
      <div>
        <label>Order Created At:</label>
        <span>{new Date(order.createdAt).toLocaleString()}</span>
      </div>
      <div>
        <label>ID:</label>
        <span>{order.cart.map((item) => item.product_id)}</span>
      </div>


      <div
  style={{
    display: 'flex', // Enable flex container
    justifyContent: 'center', // Center horizontally
    marginTop: '20px' // Add some space above the button
  }}
>
      <button 
      style={{
        padding: "5px 10px", // Smaller padding for a smaller button
        fontSize: "12px", // Smaller font size
        backgroundColor: "grey", 
        width:"70px",// Custom background color
        color: "white", // Text color
        border: "none", // Remove default border
        borderRadius: "5px", // Rounded corners
        cursor: "pointer",
        marginTop: "10px", // Cursor on hover
      }}onClick={closeModal}>Close</button>
      </div>
    </Modal>
  );
}

export default OrderDetailsModal;
