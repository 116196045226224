import React from 'react'
import { Link } from 'react-router-dom';
import img1 from '../assests/dazzle-images/proucts/full-sleevs.jpg'

import img2 from '../assests/dazzle-images/proucts/lower.jpg'
import img3 from '../assests/dazzle-images/proucts/polo-t-shirt.jpg'

import img4 from '../assests/dazzle-images/proucts/printed-t-shirt.jpg'
import img5 from '../assests/dazzle-images/proucts/round-neck.jpg'

import img6 from '../assests/dazzle-images/proucts/shorts.jpg'
import img7 from '../assests/dazzle-images/proucts/sleevless.jpg'

import img8 from '../assests/dazzle-images/proucts/track-pant.jpg'


const ProductComponent = () => {
  return (
    <>
                              <div className="col-xl-3 col-lg-4 col-sm-6 col-12 mb--30 col-xl-4-2">
                                <div className="axil-product ">
                                    <div className="thumbnail">
                                        <Link to={""}>
                                           
                                            <img  src={img1} alt="Product Images"/>
                                        </Link>
                                       
                                     
                                    </div>
                                    <div className="product-content">
                                        <div className="inner">
                                           
                                            <h5 className="title tittle-new"><Link className="tittle2" to={""}>Full Sleeves</Link></h5>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-sm-6 col-12 mb--30 col-xl-4-2">
                                <div className="axil-product ">
                                    <div className="thumbnail">
                                        <Link to={""}>
                                           
                                            <img  src={img2} alt="Product Images"/>
                                        </Link>
                                       
                                     
                                    </div>
                                    <div className="product-content">
                                        <div className="inner">
                                           
                                            <h5 className="title tittle-new"><Link className="tittle2" to={""}> Lowers</Link></h5>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-3 col-lg-4 col-sm-6 col-12 mb--30 col-xl-4-2">
                                <div className="axil-product ">
                                    <div className="thumbnail">
                                        <Link to={""}>
                                           
                                            <img  src={img3} alt="Product Images"/>
                                        </Link>
                                       
                                     
                                    </div>
                                    <div className="product-content">
                                        <div className="inner">
                                           
                                            <h5 className="title tittle-new "><Link className="tittle2" to={""}> T-shirt</Link></h5>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-3 col-lg-4 col-sm-6 col-12 mb--30 col-xl-4-2">
                                <div className="axil-product ">
                                    <div className="thumbnail">
                                        <Link to={""}>
                                           
                                            <img  src={img4} alt="Product Images"/>
                                        </Link>
                                       
                                     
                                    </div>
                                    <div className="product-content">
                                        <div className="inner">
                                           
                                            <h5 className="title tittle-new "><Link className="tittle2" to={""}>Printed T-shirts</Link></h5>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-3 col-lg-4 col-sm-6 col-12 mb--30 col-xl-4-2">
                                <div className="axil-product ">
                                    <div className="thumbnail">
                                        <Link to={""}>
                                           
                                            <img  src={img5} alt="Product Images"/>
                                        </Link>
                                       
                                     
                                    </div>
                                    <div className="product-content">
                                        <div className="inner">
                                           
                                            <h5 className="title tittle-new "><Link className="tittle2" to={""}>Round Neck</Link></h5>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-3 col-lg-4 col-sm-6 col-12 mb--30 col-xl-4-2">
                                <div className="axil-product ">
                                    <div className="thumbnail">
                                        <Link to={""}>
                                           
                                            <img  src={img6} alt="Product Images"/>
                                        </Link>
                                       
                                     
                                    </div>
                                    <div className="product-content">
                                        <div className="inner">
                                           
                                            <h5 className="title tittle-new "><Link className="tittle2" to={""}>Shorts</Link></h5>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>




                            <div className="col-xl-3 col-lg-4 col-sm-6 col-12 mb--30 col-xl-4-2">
                                <div className="axil-product ">
                                    <div className="thumbnail">
                                        <Link to={""}>
                                           
                                            <img  src={img7} alt="Product Images"/>
                                        </Link>
                                       
                                     
                                    </div>
                                    <div className="product-content">
                                        <div className="inner">
                                           
                                            <h5 className="title tittle-new "><Link className="tittle2" to={""}>Sleeveless</Link></h5>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-sm-6 col-12 mb--30 col-xl-4-2">
                                <div className="axil-product ">
                                    <div className="thumbnail">
                                        <Link to={""}>
                                           
                                            <img  src={img8} alt="Product Images"/>
                                        </Link>
                                       
                                     
                                    </div>
                                    <div className="product-content">
                                        <div className="inner">
                                           
                                            <h5 className="title tittle-new"><Link className="tittle2" to={""}>Stretch Pants</Link></h5>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
    
    
    
    </>
  )
}

export default ProductComponent
