import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from '../../assests/dazzle-images/logo.png';
import { useEffect } from 'react'; // Only import useEffect from 'react'

import metaData from '../meta.json'; // Import the metadata JSON fil
const SignUp = () => {
  useEffect(() => {
    if (metaData && metaData[9] && metaData[9].components && metaData[9].components.SignUp) {
      const { title, description } = metaData[9].components.SignUp;
      document.title = title;
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', description);
      }
    }
  }, []);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");

  const [otpSent, setOtpSent] = useState(false);
const [userOtp, setUserOtp] = useState("");
const [isOtpValid, setIsOtpValid] = useState(false);
const [isOtpStage, setIsOtpStage] = useState(false);
const [otp, setOtp] = useState("");

const handleInitialSubmit = async (event) => {
  event.preventDefault();

  try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/sendotp`, { phoneNumber, email });
      
      if (response.data.success) {
          toast.info("OTP sent successfully to your phone number. Please enter to proceed.");
          setIsOtpStage(true);
      } else {
          // Check if the error message is related to the phone number or email already being registered
          if (response.data.message === "Phone number already registered. Please login." || response.data.message === "Email already registered. Please login.") {
              toast.warning(response.data.message);
          } else {
              toast.error("Failed to send OTP.");
          }
      }
  } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
      } else {
          toast.error("An error occurred while sending OTP.");
      }
  }
};


const [isOtpVerified, setOtpVerified] = useState(false); // To track the OTP verification status

const verifyOtp = async (event) => {
  event.preventDefault();

    try {
        const otpResponse = await axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/verifyotp`, { phoneNumber, otp });
        
        if (otpResponse.data.success) {
            toast.success("OTP verified successfully!");

            const userData = {
                name,
                email,
                password,
                phoneNumber,
                isPhoneVerified: true
            };

            const registerResponse = await axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/register`, userData);
            
            if (registerResponse.data.success) {
                toast.success(registerResponse.data.message || "Registered successfully! Logging you in...");
                localStorage.setItem('token', registerResponse.data.token);
                const redirectURL = localStorage.getItem('redirectAfterLogin');
                if (redirectURL) {
                  window.location = redirectURL;
                  localStorage.removeItem('redirectAfterLogin');
              } else {
                  window.location = '/';
              }
           } else {
                toast.warning(registerResponse.data.message || "There was an issue with registration. Please try again.");
            }
        } else {
            toast.error(otpResponse.data.message || "OTP verification failed. Please try again.");
        }

    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error("An error occurred.");
        }
    }
};


  return (
    <>
      <div className="axil-signin-area">
        <div className="signin-header">
          <div className="row align-items-center">
          <div className="col-xl-4 col-sm-6">
      
      </div>
      <div className="col-md-2 d-lg-block ">
      <Link to={"/"} className="site-logo mx-5"><img src={logo} alt="logo"/></Link>
      </div>
            <div className="col-xl-6 col-lg-4 col-sm-6">
              <div className="singin-header-btn">
                <p>Already a member?</p>
                <Link
                  to={"/signin"}
                  className="axil-btn btn-bg-secondary sign-up-btn"
                >
                  Sign In
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-4 col-lg-6">
            <div className="axil-signin-banner bg_image bg_image--10">
            
            </div>
          </div>
          <div className="col-lg-6 offset-xl-2">
            <div className="axil-signin-form-wrap">
              <div className="axil-signin-form">
                <h3 className="title">I'm New Here</h3>
                <p className="b2 mb--10">Enter your detail below</p>
                <form className="singin-form" onSubmit={isOtpStage ? verifyOtp : handleInitialSubmit}>
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="username"
                      onChange={(event) => setName(event.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      onChange={(event) => setEmail(event.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      onChange={(event) => setPassword(event.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group">
    <label>Phone Number</label>
    <input
        type="text"
        className="form-control"
        name="phoneNumber"
        onChange={(event) => setPhoneNumber(event.target.value)}
        required
    />
</div>
{isOtpStage && (
                <div className="form-group">
                    <label>Enter OTP</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        required
                    />
                </div>
            )}

            <div className="form-group">
                <button
                    type="submit"
                    className="axil-btn btn-bg-primary submit-btn"
                >
                    {isOtpStage ? "Confirm Registration" : "Sign Up"}
        </button>
    </div>
    

                 
                  
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-center" autoClose={5000}     style={{ width: 'max-content' }}  />
    </>
  );
};

export default SignUp;
