import React from 'react';
import { useLocation } from 'react-router-dom';
import ProductCard from '../pages-inner-components/productCard';
import './search.css';

const SearchResults = () => {
  const location = useLocation();
  const products = location.state ? location.state.products : [];

  return (
    <div className="container">
      <div className="row">
        {products.length === 0 ? (
          <div className="col-12 text-center">
            <div className="no-results-container">
              <span className="no-results-icon">🤷‍♂️</span>
              <p className="no-results-text">Oops! No products found.</p>
              <p className="no-results-description">
                We couldn't find what you were looking for. Try searching another keyword or check out our popular categories below.
              </p>
              <div className="cta-buttons">
                <a href="/" className="cta-button btn btn-primary">Continue Shopping</a>
              </div>
            </div>
          </div>
        ) : (
          <>
            <h2 className="col-12">Your search results are:</h2>
            <div className="col-12">
              <div className="row">
                {products.map((product) => (
                  <div className="col-6 col-md-4 col-lg-3" key={product._id}>
                    <ProductCard product_cat="products" product={product} />
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SearchResults;
