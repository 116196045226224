import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './ResetPassword.css'; // Import your CSS for styling
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ResetPassword = () => {
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const isPasswordValid = (password) => {
    // At least 8 characters
    if (password.length < 8) {
      return false;
    }

    // At least one uppercase letter
    if (!/[A-Z]/.test(password)) {
      return false;
    }

    // At least one special character (you can add more characters to this regex if needed)
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      return false;
    }

    return true;
  };

  const handleResetPassword = async (event) => {
    event.preventDefault();

    if (!isPasswordValid(newPassword)) {
      toast.warning('Password must be at least 8 characters, contain an uppercase letter and a special character.');
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }


    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/resetpassword/${token}`,
        { password: newPassword }
      );
      toast.success("Password Succesfully Changed!!");
    } catch (error) {
      console.log(error);
      toast.error('Error updating password');
    }
  };

  return (
    <div className="reset-password-container">
      <h2>Reset Password</h2>
      <form onSubmit={handleResetPassword}>
        <div className="form-group">
          <label htmlFor="newPassword">New Password</label>
          <input
            type="password"
            id="newPassword"
            className="form-control"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">Confirm Password</label>
          <input
            type="password"
            id="confirmPassword"
            className="form-control"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Reset Password
        </button>
      </form>
      <ToastContainer position="top-center" autoClose={5000}     style={{ width: 'max-content' }}  />
    </div>
  );
};

export default ResetPassword;
