import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from 'react-router-dom';
import { MdFavorite, MdFavoriteBorder } from 'react-icons/md'; // Ensure you have imported the required icons
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'


function ProductCard({ product, product_cat, forceReload }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate an API call or data loading delay
    setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the delay time as needed
  }, []);
  const [isAddedToWishlist, setIsAddedToWishlist] = useState(false);
  const productId = product._id;
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();

  const handleClick = (e) => {
    if (forceReload) {
      // Force reload
      window.location.href = `/${product_cat}/${product.slug}/${product._id}`;
      window.location.reload();

    } else {
      // Normal navigation
      navigate(`/${product_cat}/${product.slug}/${product._id}`);
    }
  };
  useEffect(() => {
    if (!localStorage.getItem('token')) return; // Exit if no token is present

    const fetchProductDetails = async () => {
      try {
        const wishlistResponse = await axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/isProductInWishlist/${productId}`, {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
        });

        if (wishlistResponse.data.success) {
          setIsAddedToWishlist(wishlistResponse.data.inWishlist);
        }

      } catch (error) {
        console.error("Error in fetching data:", error);
      }
    };

    fetchProductDetails();
  }, [id, productId]);


  function capitalizeFirstLetter(string) {
    if (typeof string !== 'string' || !string) {
      return ''; // Return an empty string if the input is not a string or is undefined/null
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  }


  function calculateDiscountPercentage(originalPrice, price) {
    const percentage = (((originalPrice - price) / originalPrice) * 100).toFixed(2);
    return parseFloat(percentage);  // Always return a number
  }


  function toggleWishlist(e) {
    e.preventDefault();  // Prevent the default behavior (redirection in this case)
    e.stopPropagation();
    const token = localStorage.getItem('token');

    if (!token) {
      // Save the current page URL before redirecting
      localStorage.setItem('redirectAfterLogin', window.location.pathname);
      navigate('/signin'); // Redirect to login page
      return;
    }
    if (isAddedToWishlist) {
      axios.delete(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/removewishlist/${productId}`, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
      })
        .then(response => {
          if (response.data.success) {
            setIsAddedToWishlist(false);
            toast.success("Product removed from wishlist!"); // This will show a success toast
          } else if (response.data.message === "Product already in wishlist") {
            toast.warn(response.data.message);  // This will show a warning toast
          }
        })
        .catch(error => {
          console.error("Error removing from wishlist:", error);
        });
    } else {
      axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/addToWishlist/${productId}`, {}, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
      })
        .then(response => {
          if (response.data.success) {
            setIsAddedToWishlist(true);
            toast.success("Product added to wishlist!"); // This will show a success toast
          } else if (response.data.message === "Product already in wishlist") {
            toast.warn(response.data.message);  // This will show a warning toast
          }
        })
        .catch(error => {
          console.error("Error adding to wishlist:", error);
        });
    }
  }

  return (
    <div className='X' style={{ border: "none", }} onClick={handleClick}>
      <div key={product._id} className="col-xl-4 col-lg-6 col-sm-8 col-12 mb--10 card-coustem" style={{ width: "100%", }}>
        <div className="axil-product product-style-one"
          style={{

            width: "100%",
            border: "none",
            transition: "transform 0.3s, box-shadow 0.3s", // Smooth transition for transform and box-shadow properties
            cursor: 'pointer' // Indicate it's clickable
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.transform = "scale(1.05)"; // Enlarge the card slightly
            e.currentTarget.style.boxShadow = "0px 10px 20px rgba(0,0,0,0.1)"; // Add a shadow
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.transform = "none"; // Reset enlargement
            e.currentTarget.style.boxShadow = "none"; // Remove shadow
          }}>
          <Link style={{ display: "block", height: "100%", width: "100%" }} to={`/${product_cat}/${product.slug}/${product._id}`}>

            <div className="thumbnail" style={{ border: "none", }}>
              {loading ? (
                // Display the skeleton image with loading animation
                <Skeleton
                  height={300}
                  width={'100%'}
                  count={1}
                  className="custom-skeleton"
                />
              ) : (
                <img
                  data-sal-delay="200"
                  data-sal-duration="800"
                  loading="lazy"
                  className="main-img"

                  src={product.image[0]}
                  alt={product.title.en}

                />
              )}
              <button onClick={(e) => toggleWishlist(e)}

                style={{

                  height: "30px",
                  width: "30px",
                  textAlign: "center",
                  borderRadius: "20px",
                  background: "#fff",
                  position: "absolute",
                  right: "10px",
                  top: "10px"
                }}>
                {isAddedToWishlist ? <MdFavorite size={22} color="red" marginTop={'25px'} /> : <MdFavoriteBorder size={22} color="black" />}
              </button>
            </div>
            <div className="product-content">
              <div className="inner" style={{ textAlign: 'left' }}>
                <h5 className="title" id="mobile-tittle" style={{ fontWeight: 'bold', color: 'black', fontSize: '12px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{loading ? (
                  // Display a skeleton title with specified dimensions
                  <Skeleton height={20} width={'100%'} />
                ) : (product.title.en)}</h5>
                <p className="text" style={{ margin: '0' }}> {loading ? (
                  // Display a skeleton title with specified dimensions
                  <Skeleton height={20} width={'100%'} />
                ) : (capitalizeFirstLetter(product_cat))}</p>
                <p className="price" id="mobile-prize" style={{ margin: '0', fontWeight: 'bold', color: 'black' }}>
                  {loading ? (
                    // Display a skeleton title with specified dimensions
                    <Skeleton height={20} width={'100%'} />
                  ) : (
                    <>
                      Rs. {product.prices.price}&nbsp;
                      {product.prices.discount > 0 && product.prices.originalPrice > product.prices.price && (
                        <>
                          <del className="woocommerce-Price-amount amount" style={{ fontSize: '13px' }}>
                            Rs. {product.prices.originalPrice}
                          </del>
                          &nbsp;
                        </>
                      )}
                      {loading ? (
                        // Display a skeleton title with specified dimensions
                        <Skeleton height={20} width={'100%'} />
                      ) : (
                        <span className="text" style={{ fontSize: '13px', color: '#04ce00' }}>
                          {
                            calculateDiscountPercentage(product.prices.originalPrice, product.prices.price) > 0 &&
                            `(${calculateDiscountPercentage(product.prices.originalPrice, product.prices.price)}% Off)`
                          }
                        </span>
                      )}
                    </>
                  )}
                </p>
              </div>


            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ProductCard;
