import React from 'react';
import payment from '../assests/dazzle-images/payments.png'
import { Link } from 'react-router-dom';
import facebook from '../assests/dazzle-images/icons/facebook.png'
import instagram from '../assests/dazzle-images/icons/instagram.png'
import twitter from '../assests/dazzle-images/icons/twitter.png'
import linkedin from '../assests/dazzle-images/icons/linkedin.png'
import youtube from '../assests/dazzle-images/icons/youtube.png'
const Footer = () => {
  return (
    <>
     <footer className="axil-footer-area footer-style-2">
       
        <div className="footer-top separator-top">
            <div className="container">
                <div className="row">
                  
                    <div className="col-lg-3 col-sm-6">
                        <div className="axil-footer-widget">
                            
                           <div className=" mb--10">
                            <Link to={"/"}>
                                <img className="light-logo" src={"https://dazzlesportswear.com/images/logo.png"} alt="Logo Images"/>
                            </Link>
                           
                        </div>
                        <div className='mb--10'>
                        <img className="light-logo" src={"https://dazzlesportswear.com/images/the-dress-code-for-winners.png"} alt="Logo Images"/>
                        </div>
                        <div className="inner">
                            <p>Dazzle Sports Wear has wide range of quality sportswear and leisure wear that are affordable, trendy and comfortable.</p>
                            {/* <ul className="support-list-item">
                                  
                                   <li><i className="fal fa-map-marker-alt"></i>Dazzle Sportswear Pvt Ltd.
                                                                                <br/>Add: Plot No. 107, 107a, 108, Survey No. 509/1/2,
                                                                                <br/>Apparel Export Park, Gundlapochampally,
                                                                                <br/>Hyderabad - 500014,</li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                  
                    <div className="col-lg-3 col-sm-6">
                        <div className="axil-footer-widget">
                            <h5 className="widget-title">Quick Links</h5>
                            <div className="inner">
                                <ul>
                                     <li><Link to={"/about-us"}>About Us</Link></li>
                                     <li><Link to={"/contact-us"}>Contact Us</Link></li>
                                    <li><Link to={"/terms-and-conditions"}>Terms & Conditions</Link></li>
                                    <li><Link to={"privacy-policy"}>Privacy Policy</Link></li>
                                    <li><Link to={"/shipping-policy"}>Shipping Policy</Link></li>
                                    <li><Link to={"/exchange-policy"}>Exchange Policy</Link></li>
                                   
                                </ul>
                            </div>
                        </div>
                    </div>
                  
                    <div className="col-lg-3 col-sm-6">
                        <div className="axil-footer-widget">
                            <h5 className="widget-title">Location</h5>
                            <div className="inner">
                            <ul className="support-list-item">
                                    <li><Link to="mailto:support@dazzlesportswear.com"><i className="fal fa-envelope-open"></i>support@dazzlesportswear.com</Link></li>
                                  
                                   <li><i className="fal fa-map-marker-alt"></i>Dazzle Sports Wear Pvt Ltd.
                                                                                <br/><b>Add: </b>Plot No. 107, 107a, 108, Survey No. 509/1/2,
                                                                                <br/>Apparel Export Park, Gundlapochampally,
                                                                                <br/>Hyderabad - 500014,</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                  
                    <div className="col-lg-3 col-sm-6">
                        <h5 className="widget-title">Follow Us Here</h5>
                        
                        <div className="axil-footer-widget footer-widget-newsletter">
                           
                           
                        <div className="inner">
                          
                            <div className="social-share">
                            <Link to="https://www.facebook.com/dazzlesportswear"><img src={facebook} alt='facebook' /></Link>
                                <Link to="https://www.instagram.com/dazzlesportswear/"><img src={instagram} alt='instagram' /></Link>
                                <Link to="https://twitter.com/dazzlesportwear"><img src={twitter} alt='twitter' /></Link>
                                <Link to="https://www.linkedin.com/company/dazzlesportwear/"><img src={linkedin} alt='linkedin' /></Link>
                                <Link to="https://www.youtube.com/@dazzlesportswear"><img src={youtube} alt='youtube' /></Link>
                            </div>
                         
                              
                            </div>
                      
                            <div className="mt--20">
                            <img src={payment} width={'100%'} alt=''/>
                            </div>
                           
                           
                        </div>
                    </div>
                  
                </div>
            </div>
        </div>
       
        <div className="copyright-area copyright-default separator-top">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-4">
                       
                    </div>
                    <div className="col-xl-4 col-lg-12">
                        <div className="copyright-left d-flex flex-wrap justify-content-center">
                            <ul className="quick-link">
                                <li>© 2023. All rights reserved by <Link target="_blank" to="#">DAZZLE</Link>.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-12">
                     
                    </div>
                </div>
            </div>
        </div>
      
    </footer>
    </>
  );
};

export default Footer;