import React, { useEffect, useState, useCallback } from "react";
import ProductCard from "../productCard";
import "../../assests/css/productcard.css";

function DataDisplay({
  filterTypes,
  applyTrigger,
  selectedSubCategories,
  appliedSubCategories,
  selectedColor,
  selectedSize,
  sortBy,
  onColorsComputed
}) {
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true); // Indicates if there are more products to load
  const productsPerPage = 12; // Number of products to load per page
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [allFetchedProducts, setAllFetchedProducts] = useState([]);
  const [uniqueColors, setUniqueColors] = useState([]);

  useEffect(() => {
    setCurrentPage(1);
    setProducts([]);
  }, [selectedSubCategories, selectedColor, selectedSize, sortBy]);
 
  

  const fetchProductSizeById = async (productId) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/product/${productId}`
        );
        const data = await response.json();

        if (data && data.variants && typeof data.variants === "object") {

            // This will get the sizes for the first color. If you want a specific color, replace `firstColor` with the desired color name.
            const firstColor = Object.keys(data.variants)[0];

            if (firstColor && Array.isArray(data.variants[firstColor])) {
                const sizes = [];

                for (const variant of data.variants[firstColor]) {
                    sizes.push(variant.attr_name);
                }
                
                return sizes;
            }
        }
    } catch (error) {
        console.error("Error fetching product details:", error);
    }
    return []; // Return empty array if sizes are not found
};


const filterProductsBySize = async (allProducts, isChunked = false) => {
  if (!selectedSize) return allProducts;

  const filteredProducts = [];

  for (const product of allProducts) {
    if (!product._id) continue;
    const sizeInfo = await fetchProductSizeById(product._id);
    if (Array.isArray(sizeInfo) && sizeInfo.includes(selectedSize)) {
      filteredProducts.push(product);
    }
    
    // If chunked mode is enabled, yield the products that match the criteria as soon as they're found
    if (isChunked && filteredProducts.length) {
      return filteredProducts;
    }
  }

  return filteredProducts;
};




  const fetchProductColorById = async (productId) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/product/${productId}`
        );
        const data = await response.json();
        
        // Check if the response has the variants object and if it's of type object
        if (data && data.variants && typeof data.variants === "object") {
            // Extract the color names (keys of the variants object)
            const colors = Object.keys(data.variants);
            if (colors.length > 0) {
                return colors[0]; // Return the first color (e.g., "black")
            }
        }
    } catch (error) {
        console.error("Error fetching product details:", error);
    }
    return null; // Return null if color is not found
};


const filterProductsByColor = async (allProducts, isChunked = false) => {
  if (!selectedColor) return allProducts;

  const filteredProducts = [];
  for (const product of allProducts) {
    if (product && product._id) {
      const color = await fetchProductColorById(product._id);
      if (color === selectedColor) {
        filteredProducts.push(product);
      }
    }

    // If chunked mode is enabled, yield the products that match the criteria as soon as they're found
    if (isChunked && filteredProducts.length) {
      return filteredProducts;
    }
  }
  return filteredProducts;
};

const fetchAllProducts = async () => {
  const products = [];
  let hasMore = true;
  let page = 1;

  let allColorsSet = new Set(); // Using a Set to store unique colors
  let allSizesSet = new Set();  // Using a Set to store unique sizes

  while (hasMore) {
      const selectedSubCategoriesQuery = selectedSubCategories.join(",");
      let endpoint = `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/shorts?page=${page}&filterSubCategories=${selectedSubCategoriesQuery}`;
      const response = await fetch(endpoint);
      const data = await response.json();

      for (let product of data.products) {
          if (product.status === "show") {
              const color = await fetchProductColorById(product._id);
              const sizeArray = await fetchProductSizeById(product._id);

              if (color && !allColorsSet.has(color)) {
                  allColorsSet.add(color);
              }

              if (sizeArray && Array.isArray(sizeArray)) {
                  for (let size of sizeArray) {
                      if (!allSizesSet.has(size)) {
                          allSizesSet.add(size);
                      }
                  }
              }

              products.push(product);
          }
      }

      if (onColorsComputed) {
          onColorsComputed(Array.from(allColorsSet), Array.from(allSizesSet));
      }

      page++;
      hasMore = data.products.length > 0;
  }

  return products;
};







// ... (rest of the imports and code)
const sortProducts = (products) => {
  if (sortBy === "price_l_h") {
    products.sort((a, b) => parseFloat(a.prices.price) - parseFloat(b.prices.price));
  } else if (sortBy === "price_h_l") {
    products.sort((a, b) => parseFloat(b.prices.price) - parseFloat(a.prices.price));
  }


  return products; // Return the sorted products
};

const fetchProductsForPage = async (page = 1) => {
  try{
  const selectedSubCategoriesQuery = selectedSubCategories.join(",");
  const endpoint = `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/shorts?page=${page}&filterSubCategories=${selectedSubCategoriesQuery}`;

  const fetchedProducts = await fetch(endpoint);
  let data = await fetchedProducts.json();

  // Filter products based on the 'status' attribute
  const visibleProducts = data.products.filter(product => product.status === "show");

  if (selectedSize) {
      visibleProducts = await filterProductsBySize(visibleProducts, true);
  }

  if (selectedColor) {
      visibleProducts = await filterProductsByColor(visibleProducts, true);
  }

  return sortProducts(visibleProducts);
  }catch(error) {
    console.error('Error fetchProductsForPage:', error);
    alert(error);
    return {};
  }
};



useEffect(() => {
  const initialFetch = async () => {
    setLoading(true);

    const initialProducts = await fetchProductsForPage(1);
    setProducts(initialProducts);

    setLoading(false);
  };

  initialFetch();
}, [selectedSubCategories, selectedColor, selectedSize, sortBy]);


const fetchProducts = useCallback(async () => {
   // setLoading(true);

    let filteredProducts = [...allFetchedProducts];

    if (selectedColor) {
        filteredProducts = await filterProductsByColor(filteredProducts);
    }

    if (selectedSize) {
        filteredProducts = await filterProductsBySize(filteredProducts);
    }

    // Apply sorting
    const sortedProducts = sortProducts(filteredProducts);
    
    setProducts(sortedProducts.slice(0, productsPerPage));
   // setLoading(false);
}, [selectedSubCategories, selectedColor, selectedSize, sortBy]);

useEffect(() => {
  // Check if it's an initial load (i.e., no products have been fetched yet)
  if (allFetchedProducts.length === 0) {
      fetchAllProducts().then(products => {
          setAllFetchedProducts(products);
          setProducts(products.slice(0, productsPerPage)); // Display first page of products
      });
  }
}, []);


useEffect(() => {
  setCurrentPage(1);  // Reset to the first page
  fetchProducts();  // Fetch products for the first page
}, [selectedSubCategories, selectedColor, selectedSize, sortBy, fetchProducts]);

// For infinite scroll, attach and detach the scroll event listener
useEffect(() => {
  const handleScroll = async () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;

    if (scrollTop + clientHeight >= scrollHeight - 12 && hasMore && !loading) {
      setLoadingMore(true);

      const nextPageProducts = await fetchProductsForPage(currentPage + 1);

      if (nextPageProducts.length > 0) {
        setProducts(prev => [...prev, ...nextPageProducts]);
        setCurrentPage(prevPage => prevPage + 1);
      } else {
        setHasMore(false);
      }

      setLoadingMore(false);
    }
  };

  window.addEventListener("scroll", handleScroll);
  return () => window.removeEventListener("scroll", handleScroll);

}, [hasMore, loading, currentPage, selectedSubCategories, selectedColor, selectedSize, sortBy]);


  return (
<div className="row" style={{paddingLeft:"8px",paddingRight:"8px"}}>
      
{loading ? (
        <p>**********Loading products...****************</p>
      ) :products.length > 0 ? (
          products.map((product, index) => (
            <div  className="col-6 col-md-4 col-lg-3 padding-new">
            <ProductCard
              key={index}
              product={product}
              product_cat={"shorts"}
            />
            </div>
          ))
        ) : (
          <p>No products matching your filters.</p>
        )}
      
    </div>
  );
}

export default DataDisplay;