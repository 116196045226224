import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import metaData from '../pages/meta.json';
import Skeleton from 'react-loading-skeleton';

import img1 from '../assests/dazzle-images/tshirts-collection/PC Matty Slim Fit T-shirt.jpg'

import img2 from '../assests/dazzle-images/tshirts-collection/PC Matty Comfort Fit T-shirt.jpg'
import img3 from '../assests/dazzle-images/tshirts-collection/Butter Lycra Round Neck T-shirt.jpg'

import img4 from '../assests/dazzle-images/tshirts-collection/Printed Round Neck T-shirt.jpg'
import img5 from '../assests/dazzle-images/tshirts-collection/PC Round Neck T-Shirt.jpg'

import img6 from '../assests/dazzle-images/tshirts-collection/Round Neck Full Sleeves T-Shirt.jpg'
import img7 from '../assests/dazzle-images/tshirts-collection/V Neck T-Shirt.jpg'

import img8 from '../assests/dazzle-images/tshirts-collection/PC Polo T-Shirt.jpg'
import img9 from '../assests/dazzle-images/tshirts-collection/Matty Trendy T-shirt.jpg'
import img10 from '../assests/dazzle-images/tshirts-collection/Cricket T-shirt.jpg'

import 'react-loading-skeleton/dist/skeleton.css'
import { Helmet } from 'react-helmet';



// Define a JSON array with product data
const products = [
    {
      title: 'PC Matty Slim Fit T-shirt',
      image: img1,
      link: '/tshirts',
    },
    {
      title: 'PC Matty Comfort Fit T-shirt',
      image: img2,
      link: '/tshirts',
    },
    {
      title: 'Butter Lycra Round Neck T-shirt',
      image: img3,
      link: '/tshirts',
    },
    {
      title: 'Printed Round Neck T-shirt',
      image: img4,
      link: '/tshirts',
    },
    {
      title: 'PC Round Neck T-Shirt',
      image: img5,
      link: '/tshirts',
    },
    {
      title: 'Round Neck Full Sleeves T-Shirt',
      image: img6,
      link: '/tshirts',
    },
    {
      title: 'V Neck T-Shirt',
      image: img7,
      link: '/tshirts',
    },
    {
      title: 'PC Polo T-Shirt',
      image: img8,
      link: '/tshirts',
    },
    {
      title: 'Matty Trendy T-shirt',
      image: img9,
      link: '/tshirts',
      },
    {
      title: 'Cricket T-shirt',
      image: img10,
      link: '/tshirts',
    }
  ];

  const TshirtsCollections = () => {
    useEffect(() => {
      if (metaData && metaData[17] && metaData[17].components && metaData[17].components.TshirtsCollections) {
        const { title, description } = metaData[17].components.TshirtsCollections;
        document.title = title;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute('content', description);
        }
      }
    }, []);
    const [loading, setLoading] = useState(true);
   
  
    useEffect(() => {
     

      // Simulate an API call or data loading delay
      setTimeout(() => {
        setLoading(false);

      }, 2000); // Adjust the delay time as needed
    }, []);
  
    return (
      <>
        <div className="axil-product-area bg-color-white">
          <div className="container">
            <div className="py-5">
              <h2 className="title text-center">Tshirts collections</h2>
            </div>
            <div className="explore-product-activation slick-layout-wrapper slick-layout-wrapper--15 axil-slick-arrow arrow-top-slide">
              <div className="">
                <div class="row row--15 row--15-2">
                  {products.map((product, index) => (
                    <div key={index} className="col-xl-3 col-lg-4 col-sm-6 col-12 mb--30 col-xl-4-2">
                      <div className="axil-product">
                        <div className="thumbnail">
                        <Link to={`${product.link}?type=${encodeURIComponent(product.title)}`}>
                    <div className="image-container">
                      {loading ? (
                        // Display the skeleton image with loading animation
                        <Skeleton
                          height={300}
                          width={'100%'}
                          count={1}
                          className="custom-skeleton"
                        />
                      ) : (
                        // Display the actual product image when loaded
                        <img
                          src={product.image}
                          alt="Product Images"
                          className="img-fluid"
                          width="100%"
                          height="100%"
                        />
                      )}
                    </div>
                  </Link>
                        </div>
                        <div className="product-content">
                          <div className="inner">
                            <h5 className="title tittle-new">
                              <Link className="tittle2" to={product.link}>
                                {loading ? (
                                  // Display a skeleton title with specified dimensions
                                  <Skeleton height={20} width={'100%'} />
                                ) : (
                                  // Display the actual product title when loaded
                                  product.title
                                )}
                              </Link>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  
  export default TshirtsCollections;