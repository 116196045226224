import React, { useEffect, useState, useReducer } from "react";
import FooterService from "../pages-inner-components/FooterService";
import { useNavigate } from "react-router-dom";
import bgimg2 from '../assests/dazzle-images/new-banners/tshirts-banner.jpg';
import DataDisplay from "../pages-inner-components/data_components/tshirtData";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import metaData from './meta.json'; // Import the metadata JSON file

import axios from "axios";
import "./tshirts.css";

const Tshirts = () => {
  useEffect(() => {
    if (metaData && metaData[10] && metaData[10].components && metaData[10].components.Tshirts) {
      const { title, description } = metaData[10].components.Tshirts;
      document.title = title;
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', description);
      }
    }
  }, []);
  const [selectedColor, setSelectedColor] = useState(null);

  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [simplifiedAttributes, setAttributeDetails] = useState([]);
  const [filterSubCategories, setFilterSubCategories] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const navigate = useNavigate();
  const [subCategories, setSubCategories] = useState([]);
  const [applyTrigger, setApplyTrigger] = useState(false);
  const [colorToBeApplied, setColorToBeApplied] = useState(null);
  const [sortBy, setSortBy] = useState("relevance"); // Add this line
  const [appliedSortBy, setAppliedSortBy] = useState("relevance");

  const [appliedSubCategories, setAppliedSubCategories] = useState([]);
  const [availableColors, setAvailableColors] = useState([]); // NEW
  const [selectedSize, setSelectedSize] = useState(null);
  const [sizeToBeApplied, setSizeToBeApplied] = useState(null);
  const [uniqueColors, setUniqueColors] = useState([]);
  const [uniqueProductColors, setUniqueProductColors] = useState([]);
  const [uniqueSizes, setUniqueSizes] = useState([]);
  const [showDataDisplay, setShowDataDisplay] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowDataDisplay(true);
    }, 100); // 10 seconds delay

    return () => clearTimeout(timer); // Cleanup
  }, []);
 
  useEffect(() => {
    // When the component mounts or the URL changes, check for the 'type' query parameter and set it to state
    const params = new URLSearchParams(location.search);
    const type = params.get('type');

    if (type) {
      setTimeout(() => {
      setSelectedSubCategories([type]);
    }, 100);
    }
  }, [location]);
  
  const updateProductColors = (colors, sizes) => {
    setUniqueProductColors(colors);
    setUniqueSizes(sizes)

}
  
  useEffect(() => {
    // Fetch attribute details
    axios
      .get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/getAllAttributes`)
      .then((response) => {
        const allAttributes = response.data.simplifiedAttributes || [];

        // Get all attribute names (colors)
        const colorNames = allAttributes.map((attr) => attr.name);
        setAvailableColors(colorNames);
      })
      .catch((error) => {
        console.error("Error fetching attribute details:", error);
      });
  }, []);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/tshirts`)
      .then((response) => {
        if (response.data.filterSubCategories) {
          setSubCategories(response.data.filterSubCategories);
        }
      })
      .catch((error) => {
        console.error("Error fetching Tshirts:", error);
      });
  }, []);
  const handleApplyFilter = () => {
    setSelectedColor(colorToBeApplied);
    setAppliedSubCategories([...selectedSubCategories]);
    setSelectedSize(sizeToBeApplied);  // Add this line
    setApplyTrigger(!applyTrigger);
    setAppliedSortBy(sortBy); // Set the applied sort-by option

  };
  
  const toggleSubCategory = (subCategory) => {
    setSelectedSubCategories((prevState) => {
      if (prevState.includes(subCategory)) {
        return prevState.filter((sc) => sc !== subCategory);
      } else {
        return [...prevState, subCategory];
      }
    });
  };
  

  const resetAllFilters = () => {
    setSelectedColor(null);
    setSelectedSize(null);
    setAppliedSortBy("relevance");
    setSelectedSubCategories([]); // If you want to reset subcategory filters as well
};

  useEffect(() => {
    // Check if data is available in localStorage
    const storedData = localStorage.getItem("attributeData");
    if (storedData) {
      setAttributeDetails(JSON.parse(storedData));
      const colorsData = JSON.parse(storedData).filter(
        (attribute) => attribute.name === "Colors"
      );
      setColors(colorsData);
      const sizesData = JSON.parse(storedData).filter(
        (attribute) => attribute.name === "Size"
      );
      setSizes(sizesData);
    } else {
      // Fetch COLORS data if not in localStorage
      axios
        .get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/getAllAttributes`)
        .then((response) => {
          const data = response.data.simplifiedAttributes;

          setAttributeDetails(data);
          localStorage.setItem("attributeData", JSON.stringify(data));

          const colorsData = data.filter(
            (attribute) => attribute.name === "Colors"
          );
          setColors(colorsData);

          const sizesData = JSON.parse(storedData).filter(
            (attribute) => attribute.name === "Size"
          );
          setSizes(sizesData);
        })
        .catch((error) => {
          console.error("Error fetching COLORS:", error);
        });
    }
  }, []);
  const [selectedColors, setSelectedColors] = useState([]);

  // ... other code ...
  const toggleColorSelection = (color) => {
    if (selectedColor === color) {
      // If the selected color is clicked again, deselect it
      setSelectedColor(null);
    } else {
      // If a different color is clicked, select it
      setSelectedColor(color);
    }
  };
  

  const toggleSizeSelection = (size) => {
    setSelectedSize(prevSize => {
        if (prevSize === size) {
            return null; // Deselect the size
        } else {
            return size; // Select the new size
        }
    });
};

  return (
    <>
      <main className="main-wrapper">
                <div className="row align-items-center">
                    <div className="col-md-12">
                      <img width="100%" src={bgimg2} alt=''/>
                    </div>
                   
               
      
        </div>
     </main>
      <div className="axil-shop-area axil-section-gap bg-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="axil-shop-sidebar">
                <div className="d-lg-none">
                  <button className="sidebar-close filter-close-btn">
                    <i className="fas fa-times"></i>
                  </button>
                </div>
                <div className="toggle-list product-color active">
                  <h6 className="title">COLORS</h6>
                  <div className="shop-submenu">
                    <ul>
                    {uniqueProductColors.map((color, index) => (
    <li
        key={index}
        className={`color-item ${selectedColor === color ? "selected" : ""}`}
    >
        <Link
            to="#"
            style={{ backgroundColor: color }}
            onClick={(e) => {
                e.preventDefault();
                toggleColorSelection(color);
            }}
        >
            {/* Text can be added here if needed */}
        </Link>
    </li>
))}



                    </ul>
                  </div>
                </div>
                <div className="toggle-list product-size active">
    <h6 className="title">SIZE</h6>
    <div className="shop-submenu">
        <ul>
            {uniqueSizes.map((size, index) => (
                <li key={index}>
                    <button
                        className={`axil-btn size-button ${selectedSize === size ? "selected" : ""}`}
                        onClick={() => toggleSizeSelection(size)}
                    >
                        {size}
                    </button>
                </li>
            ))}
        </ul>
    </div>
</div>


                {subCategories.length > 0 && (
  <div className="toggle-list product-types active">
    <h6 className="title">TYPE</h6>
    <div className="shop-submenu">
      <ul>
        {subCategories.map((subCategory, index) => (
          <li key={index}>
            <button
              className={`axil-btn size-button ${
                selectedSubCategories.includes(subCategory.en) ? "selected" : ""
              }`}
              onClick={() => toggleSubCategory(subCategory.en)}
            >
              {subCategory.en}
            </button>
          </li>
        ))}
      </ul>
    </div>
  </div>
)}

                <div className="toggle-list product-color active">
                  <h6 className="title">Sort by:</h6>
                  <div className="shop-submenu">
                  <select 
  value={sortBy}
  onChange={(e) => {
    setSortBy(e.target.value);
    setAppliedSortBy(e.target.value);
  }}
>
  <option value="price_l_h">Price low to high</option>
  <option value="price_h_l">Price high to low</option>
  <option value="relevance">Relevance</option>
</select>

        </div>
        </div>

                
                <button className="axil-btn btn-bg-primary" onClick={resetAllFilters}>All Reset</button>
              </div>
            </div>
            <div className="col-lg-9">
            <div className="row">
                            <div className="col-lg-12">
                                <div className="axil-shop-top ">
                                    <div className="category-select align-items-center justify-content-lg-start justify-content-between">
                                       
                                    <h2 className="catagry-tittle" style={{ marginLeft: '20px',}}>Tshirts </h2>
                                       
                                       
                                    </div>
                                    <div className="d-lg-none " style={{ marginLeft: '20px',}}>
                                        <button className="product-filter-mobile filter-toggle"><i className="fas fa-filter"></i> FILTER</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="data-display-container">
        {showDataDisplay ? (
          <DataDisplay
            selectedSubCategories={selectedSubCategories}
            selectedColor={selectedColor}
            selectedSize={selectedSize}
            sortBy={appliedSortBy}
            onColorsComputed={updateProductColors}
          />
        ) : (
          <div className="loading-container">
            <p>Loading...</p>
          </div>
        )}
      </div>
            </div>
          </div>
        </div>
      </div>
      
      <FooterService className="bg-white" />
    </>
  );
};

export default Tshirts;
