import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const BlogPostPage = () => {
    const { id } = useParams();
    const [blogPost, setBlogPost] = useState(null);

    useEffect(() => {
        const fetchBlogPost = async () => {
            try {
                const response = await fetch(`https://backend.dazzlesportswear.com/api/v1/blog/${id}`);
                const data = await response.json();
                if (!response.ok) throw new Error(data.message || 'Could not fetch blog post');
                setBlogPost(data);
            } catch (err) {
                console.error('Error fetching blog post:', err.message);
            }
        };

        fetchBlogPost();
    }, [id]);

    if (!blogPost) {
        return <div className="d-flex justify-content-center align-items-center">
                   <div className="spinner-border text-primary" role="status">
                       <span className="sr-only">Loading...</span>
                   </div>
               </div>;
    }

    // Format dates
    const formatDate = (dateString) => {
      const options = { year: 'numeric', month: 'long', day: 'numeric'};
      return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return (
        <div className="container my-5">
            <div className="text-center mb-4">
            
         
            <img src={blogPost.imageUrl} alt="Blog Images" className="thumbnail-img"  /> {/* Added custom class */}
         
      
                <h1 className="display-3 mt-5">{blogPost.title}</h1>
                <p className="text-muted">
                    <span>Created: {formatDate(blogPost.createdAt)}</span> <br />
                    <span>Last Updated: {formatDate(blogPost.updatedAt)}</span>
                </p>
            </div>
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <div dangerouslySetInnerHTML={{ __html: blogPost.description }} />
                </div>
            </div>
        </div>
    );
};

export default BlogPostPage;
