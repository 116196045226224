import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import metaData from '../pages/meta.json';
import Skeleton from 'react-loading-skeleton';

import img1 from '../assests/dazzle-images/lowers-colection/PC-Jogger.jpg'

import img2 from '../assests/dazzle-images/lowers-colection/Melange-Lower.jpg'
import img3 from '../assests/dazzle-images/lowers-colection/jpeg-optimizer_dazal30035.JPG'

import img4 from '../assests/dazzle-images/lowers-colection/jpeg-optimizer_dazal30155.JPG'
import img5 from '../assests/dazzle-images/lowers-colection/Lowers-collection.jpg'

import img6 from '../assests/dazzle-images/lowers-colection/jpeg-optimizer_dazal32628.JPG'
import img7 from '../assests/dazzle-images/lowers-colection/Young.JPG'
import 'react-loading-skeleton/dist/skeleton.css'


// Define a JSON array with product data
const products = [
    {
      title: 'PC Jogger',
      image: img1,
      link: '/lowers',
    },
    {
      title: 'Melange Lower',
      image: img2,
      link: '/lowers',
    },
    {
      title: 'Walker',
      image: img3,
      link: '/lowers',
    },
    {
      title: 'PC Special Lower',
      image: img4,
      link: '/lowers',
    },
    {
      title: 'All Rounder',
      image: img5,
      link: '/lowers',
    },
    {
      title: 'Runner',
      image: img6,
      link: '/lowers',
    },
    {
      title: 'Younger',
      image: img7,
      link: '/lowers',
    },
   
  ];

  const LowersCollections = () => {
    useEffect(() => {
      if (metaData && metaData[16] && metaData[16].components && metaData[16].components.LowersCollections) {
        const { title, description } = metaData[16].components.LowersCollections;
        document.title = title;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute('content', description);
        }
      }
    }, []);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
     

      // Simulate an API call or data loading delay
      setTimeout(() => {
        setLoading(false);
      }, 2000); // Adjust the delay time as needed
    }, []);
  
    return (
      <>
        <div className="axil-product-area bg-color-white">
          <div className="container">
            <div className="py-5">
              <h2 className="title text-center">Lowers collections</h2>
            </div>
            <div className="explore-product-activation slick-layout-wrapper slick-layout-wrapper--15 axil-slick-arrow arrow-top-slide">
              <div className="">
                <div class="row row--15 row--15-2">
                  {products.map((product, index) => (
                    <div key={index} className="col-xl-3 col-lg-4 col-sm-6 col-12 mb--30 col-xl-4-2">
                      <div className="axil-product">
                        <div className="thumbnail">
                        <Link to={`${product.link}?type=${encodeURIComponent(product.title)}`}>
                    <div className="image-container">
                      {loading ? (
                        // Display the skeleton image with loading animation
                        <Skeleton
                          height={300}
                          width={'100%'}
                          count={1}
                          className="custom-skeleton"
                        />
                      ) : (
                        // Display the actual product image when loaded
                        <img
                          src={product.image}
                          alt="Product Images"
                          className="img-fluid"
                          width="100%"
                          height="100%"
                        />
                      )}
                    </div>
                  </Link>
                        </div>
                        <div className="product-content">
                          <div className="inner">
                            <h5 className="title tittle-new">
                              <Link className="tittle2" to={product.link}>
                                {loading ? (
                                  // Display a skeleton title with specified dimensions
                                  <Skeleton height={20} width={'100%'} />
                                ) : (
                                  // Display the actual product title when loaded
                                  product.title
                                )}
                              </Link>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  
  export default LowersCollections;