import { useEffect } from 'react'; // Only import useEffect from 'react'
import { Link } from 'react-router-dom';
import metaData from './meta.json'; // Import the metadata JSON file
import founder from '../assests/dazzle-images/md_grey.jpg'
import building from '../assests/dazzle-images/dazzle_building.jpg'
import vision from '../assests/dazzle-images/vision.jpg'
import mission from '../assests/dazzle-images/mission.jpg'
import FooterService from '../pages-inner-components/FooterService';
const About = () => {
    useEffect(() => {
        if (metaData && metaData[1] && metaData[1].components && metaData[1].components.About) {
          const { title, description } = metaData[1].components.About;
          document.title = title;
          const metaDescription = document.querySelector('meta[name="description"]');
          if (metaDescription) {
            metaDescription.setAttribute('content', description);
          }
        }
      }, []);
      
  return (
    <>
    <main class="main-wrapper">
      
        <div class="axil-breadcrumb-area">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-8">
                        <div class="inner">
                            <ul class="axil-breadcrumb">
                                <li class="axil-breadcrumb-item"><Link to={"/"}>Home</Link></li>
                                <li class="separator"></li>
                                <li class="axil-breadcrumb-item active" aria-current="page">About Us</li>
                            </ul>
                            <h1 class="title">About Us</h1>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-4">
                        <div class="inner">
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="axil-about-area about-style-1 mt--20 ">
            <div class="container">
                <div className='row'>
                    <div className='col-md-12'>
                    <img src={building} alt="about"/>
                    </div>
                </div>
                <div className='row mt--20'>
                    <div className='col-md-12'>
                    <h3 class="title">History====</h3>
                    <span class="text-heading">The company is the brain child of Paladugu Venkateshwar Rao (PVR) who founded it in 2006. He launched Dazzle Sportswear to compete with world class sports and leisure wear brands. He was an acclaimed international Volleyball player who proudly represented India at various prestigious tournaments across the world. PVR used his experience and exposure as an international sportsman to comprehend the specific apparel needs of sports persons. He then got into research and development activities to innovate customized sportswear that augments the needs of sports persons pertaining to various disciplines. He always had solid functional support from his partners,</span>
                    <p>SP Ramya Shanker and V Chandana Pradeep who were also international Volleyball players. Gradually the company diversified into leisure wear and experienced hyper growth in that sector. Today Dazzle has a massive leisure wear portfolio catering to the fashion needs of diverse age groups and social categories. Dazzle was initially launched as a small scale business unit and has come a long way since. Today, it is one of the most admired and respected apparel brands in India. The organization was just 4 strong when it was launched and today it directly employs over 1000 full time resources.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="axil-about-area about-style-1 axil-section-gap ">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-xl-4 col-lg-6">
                        <div class="about-thumbnail">
                            <div class="thumbnail">
                                <img src={founder} width="100%" height="100%" alt="About Us"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-8 col-lg-6">
                        <div class="about-content content-right">
                           
                            <h3 class="title">The Leadership Team</h3>
                            <p>Paladugu Venkateshwar Rao (PVR) - He is the founding CMD of the organization and spear heads the company's functionality. Thanks to his stint as an international Volleyball player, he deeply connects with the intricacies involved in manufacturing cutting edge sportswear and leisure wear. He is backed by considerable administrative experience courtesy his erstwhile role as a government employee. The core leadership also comprises of the directors and partners SP Ramya Shanker, V Chandana Pradeep and Mrudula Paladugu. Each of them handles a specific organizational function and strives to drive the brand up the value curve on a sustained format. The senior leadership is backed by varied cumulative experience and niche expertise. All members of the senior leadership operate on a seamless, collaborative model that brooks a unique synergy.</p>
                           
                        </div>
                    </div>
                </div>
                <div className='row mt--20'>
                    <div className='col-md-12'>
                    <span class="title-highlighter highlighter-primary2"> <i class="fal fa-envelope-open"></i>MD's Message</span>
                            <p>"I have always been a passionate sportsman and also an avid fan of different sports. I look at the specific clothing requirements of sports persons from a vantage point thanks to my sports background. I have always leveraged my intricate sports knowledge to shape the very best of sportswear and leisure wear. Quality is my watch word and am proud to say that every single Dazzle product undergoes a range of stringent quality circles before hitting the shelves. Manufacturing and retailing world class sportswear and leisure wear with value for money pricing was always my guiding factor. Total commitment to world class quality has got us this far. Today we are the market leaders with an impressive track record, robust clientele list and over 1000 employees. The fact that we are able to generate so many direct and indirect employment opportunities gives me true joy. And most of our employees are women which makes it even more special. I am also proud of our CSR wing which has been reaching out to the needy at multiple levels on a consistent basis. I will always cherish the faith reiterated in us by our customers and the solid support lent by our distributor network." - PVR</p>
                    </div>
                </div>
            </div>
        </div>
       
        <div class="about-info-area">
            <div class="container">
                <div class="row row--20">
                    <div class="col-lg-4">
                        <div class="about-info-box">
                            <div class="thumb">
                                <img src="assets/images/about/shape-01.png" alt="Shape"/>
                            </div>
                            <div class="content">
                                <h6 class="title">40,000+ Happy Customer</h6>
                                <p>Empower your sales teams with industry
                                    tailored solutions that support.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="about-info-box">
                            <div class="thumb">
                                <img src="assets/images/about/shape-02.png" alt="Shape"/>
                            </div>
                            <div class="content">
                                <h6 class="title">17 Years of Experiences</h6>
                                <p>Empower your sales teams with industry
                                    tailored solutions that support.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="about-info-box">
                            <div class="thumb">
                                <img src="assets/images/about/shape-03.png" alt="Shape"/>
                            </div>
                            <div class="content">
                                <h6 class="title">12 Awards Won</h6>
                                <p>Empower your sales teams with industry
                                    tailored solutions that support.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
       
      
       
        <div class="axil-about-area about-style-2 mb--80">
            <div class="container">
                <div class="row align-items-center mb--80 mb_sm--60">
                    <div class="col-lg-5">
                        <div class="about-thumbnail">
                            <img src={mission} alt="about"/>
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <div class="about-content content-right">
                            
                            <h4 class="title">Mission</h4>
                            <p>To be recognized as one of the most successful ‘make in India’ casual wear brands and evolve into a full-fledged global player catering to a vast consumer segment via a versatile product mix.</p>
                           
                        </div>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-lg-5 order-lg-2">
                        <div class="about-thumbnail">
                            <img src={vision} alt="about"/>
                        </div>
                    </div>
                    <div class="col-lg-7 order-lg-1">
                        <div class="about-content content-left">
                           
                            <h4 class="title">Vision</h4>
                            <p>To be the one stop solution in high end leisure wear that seamlessly integrates quality, durability, style and affordability augmenting the needs of fashion enthusiasts across age groups; to leverage world class skill, cutting edge infrastructure, emerging technologies and astute leadership to aid the brand’s exponential growth on a sustained format and consolidate market leading presence.</p>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <FooterService />
       
    </main>
    
    </>
  )
}

export default About