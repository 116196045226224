import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

import "./blog.css";
import metaData from './meta.json'; // Import the metadata JSON file

const Blog = () => {
  useEffect(() => {
    if (metaData && metaData[13] && metaData[13].components && metaData[13].components.Blog) {
      const { title, description } = metaData[13].components.Blog;
      document.title = title;
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', description);
      }
    }
  }, []);
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await fetch('https://backend.dazzlesportswear.com/api/v1/blog');
      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Could not fetch blogs');
      setBlogs(data);

    } catch (err) {
      console.error('Error fetching blogs:', err.message);
    }
  };

  const ReadMore = ({ htmlContent, id, maxLength = 150 }) => {
    const [isTruncated, setIsTruncated] = useState(true);

    const stripHtml = (html) => {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = html;
      return tempDiv.textContent || tempDiv.innerText || "";
    };

    const textLength = stripHtml(htmlContent).length;

    const truncateHtml = (html, maxLen) => {
      if (stripHtml(html).length <= maxLen) return html;
      let truncated = html.slice(0, maxLen);
      truncated += '...';
      return truncated;
    };

    const resultString = isTruncated ? truncateHtml(htmlContent, maxLength) : htmlContent;

    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: resultString }} />
        {textLength > maxLength && (
          <Link className="axil-btn right-icon" to={`/blog/${id}`}>
            {isTruncated ? 'Read More ' : ' Show Less '}
            <i className={`fal fa-long-arrow-${isTruncated ? 'right' : 'left'}`}></i>
          </Link>
        )}
      </div>
    );
  };

  return (
    <div>
      <main className="main-wrapper">
        <div className="axil-breadcrumb-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 col-md-12 text-center">
                <div className="inner">
                  <ul className="axil-breadcrumb">
                    <li className="axil-breadcrumb-item"><Link to="index.html">Home</Link></li>
                    <li className="separator"></li>
                    <li className="axil-breadcrumb-item active" aria-current="page">Blogs</li>
                  </ul>
                  <h1 className="title">Blog</h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="axil-blog-area axil-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="row g-5">
                  {blogs.map((blog) => (
                    <div key={blog._id} className="col-md-6">
                      <Link to={`/blog/${blog._id}`} className="content-blog blog-grid card-blog-grid"> 
                        <div className="inner">
                          <div className="thumbnail">
                            <img src={blog.imageUrl} alt="Blog Images" className="thumbnail-img" />
                          </div>
                          <div className="content">
                            <h5 className="title">{blog.title}</h5>
                            <div className="read-more-btn">
                              <button id={blog._id}  className="btn "  style={{backgroundColor:'#f04f23', color:'white', padding:'10px 20px',borderRadius:'10px', fontSize:'18px'}}>Read More</button>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Blog;
