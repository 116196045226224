/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
    import { toast } from "react-toastify";
    import metaData from './meta.json'; // Import the metadata JSON file
import './wishlist.css'
const Wishlist = () => {
    useEffect(() => {
        if (metaData && metaData[8] && metaData[8].components && metaData[8].components.Wishlist) {
          const { title, description } = metaData[8].components.Wishlist;
          document.title = title;
          const metaDescription = document.querySelector('meta[name="description"]');
          if (metaDescription) {
            metaDescription.setAttribute('content', description);
          }
        }
      }, []);
    const [wishlist, setWishlist] = useState([]);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem('token');
    const [cartData, setCartData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedSize, setSelectedSize] = useState("");
    const [selectedProductSizes, setSelectedProductSizes] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [attributeVariants, setAttributeVariants] = useState([]);

    const openModal = (product) => {
        const size = products.find(p => p._id === product._id);
        if (size && size.sizes) {
            setSelectedProductSizes(size.sizes);
        }
        setSelectedProduct(product);
        setShowModal(true);
    };
    
  
    const closeModal = () => {
      setShowModal(false);
      setSelectedProduct(null);
      setSelectedSize("");
    };
  
    const handleSizeClick = (variant) => {
        const newSize = variant.attr_name; // Extract attr_name from the variant
        setSelectedSize((prevSize) => (prevSize === newSize ? '' : newSize));
    };
    
    
  
    const handleAddToCart = () => {
        if (!selectedSize) {
            setErrorMessage("Please select a size");
            return;
        }
        
        setErrorMessage("");

        addProductToCart(selectedProduct._id, selectedSize) // Include selectedSize as a parameter
        .then(() => {
            // Update the cartData state here
            setCartData(prevCartData => [...prevCartData, selectedProduct]);
        })
        .finally(() => {
            closeModal();
        });
    };
    
    async function addProductToCart(productId, selectedSize) {
        const token = localStorage.getItem('token');
        
        if (!token) {
            toast.warning("User not logged in");
            return Promise.reject(); // Return a rejected promise
        }
    
        // Prepare the payload to include the selected size
        const payload = {
            size: selectedSize
        };
    
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/addToCart/${productId}`, payload, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (response.data.success) {
                toast.success(response.data.message);
            } else {
                toast.warning(response.data.message);
            }
        } catch (error) {
            if (error.response && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                console.error("Error adding to cart:", error);
                toast.error("An error occurred. Please try again later.");
            }
        }
    }
    
  


    useEffect(() => {
        const fetchCartData = async () => {
          const token = localStorage.getItem('token');
          if (token) {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/getCart`, {
              headers: { 'Authorization': `Bearer ${token}` }
            });
            setCartData(response.data.cart);
          }
        };
        fetchCartData();
      }, []);
    useEffect(() => {
        if (!token) {
            setLoading(false);
            return;
        }

        axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/getwishlist`, {
            headers: { 'Authorization': `Bearer ${token}` }
        })
        .then(response => {
            setWishlist(response.data.wishlist);
        })
        .catch(error => {
            console.error("Error fetching wishlist:", error);
        });
    }, []);

    const isProductInCart = (productId) => {
        return cartData.some(item => item._id === productId);
      };
      
      useEffect(() => {
        if (wishlist.length) {
            Promise.all(wishlist.map(productId => 
                axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/product/${productId}`)
            ))

.then(results => {
    let accumulatedVariants = [];
    
    results.forEach(result => {
        if (result.data.variants) {
            const firstAttrTitle = Object.keys(result.data.variants)[0];
            accumulatedVariants.push(...result.data.variants[firstAttrTitle]);
        }
    });
    
    setAttributeVariants(accumulatedVariants);

    const updatedProducts = results.map(result => {
        const product = result.data.product;
        let sizes = [];
        let attributeVariants = [];   // add this
    
        if (result.data.variants) {
            const firstVariantKey = Object.keys(result.data.variants)[0];
            const sizeObjects = result.data.variants[firstVariantKey];
            attributeVariants = sizeObjects;  // set the size objects here
    
            if (sizeObjects) {
                for (const key in sizeObjects) {
                    if (sizeObjects.hasOwnProperty(key) && sizeObjects[key].attr_name) {
                        sizes.push(sizeObjects[key].attr_name);
                    }
                }
            }
        }

        return { ...product, sizes, attributeVariants };  // return attributeVariants as part of each product
    });
                setProducts(updatedProducts);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching product details:", error);
            });
        } else {
            setLoading(false);
        }
    }, [wishlist]);
    
    
    

    const removeFromWishlist = (productId) => {
        axios.delete(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/removewishlist/${productId}`, {
            headers: { 'Authorization': `Bearer ${token}` }
        })
        .then(response => {
            setProducts(prevProducts => prevProducts.filter(product => product._id !== productId));
            toast.success('Product removed from wishlist.');
        })
        .catch(error => {
            console.error("Error removing product from wishlist:", error);
        });
    };

    
    

    if (loading) return <div>Loading...</div>;
    if (!token) return <div style={{ textAlign: 'center', marginTop: '20px' }}>User not logged in</div>;
    if (!products.length) 
    return (
        <div className="container" style={{ textAlign: 'center', marginTop: '50px' }}>
            <div className="no-wishlist-card">
                {/* Using a heart icon (❤) as an example. You can replace it with any icon. */}
                <div className="no-wishlist-icon">❤</div> 
                <div className="no-wishlist-text">No items in your wishlist</div>
            </div>
            
            {/* Quick Links */}
            <div className="quick-links">
                <div className="quick-links-heading">Quick Links</div>
                <Link to="/" className="category-link">Home</Link>
                <Link to="/tshirts" className="category-link">T-Shirts</Link>
                <Link to="/shorts" className="category-link">Shorts</Link>
                <Link to="/lowers" className="category-link">Lowers</Link>
                {/* Add more links as required */}
            </div>
        </div>
    );


    return (
        <div className="container">
            <div className="row ">
            <div className="col-md-12">
          
            <div className="axil-wishlist-area axil-section-gap">
    <div className="container">
        <div className="product-table-heading">
            <h4 className="title">My Wish List</h4>
        </div>
        <div className="table-responsive">
            <table className="table axil-product-table axil-wishlist-table">
                <thead>
                    <tr>
                        <th scope="col" className="product-remove"></th>
                        <th scope="col" className="product-thumbnail">Product</th>
                        <th scope="col" className="product-title"></th>
                        <th scope="col" className="product-price">Unit Price</th>
                        <th scope="col" className="product-stock-status">Stock Status</th>
                        <th scope="col" className="product-add-cart"></th>
                    </tr>
                </thead>
                <tbody>
                    {products.map(product => (
                        <tr key={product._id}>
                            <td className="product-remove"><button onClick={() => removeFromWishlist(product._id)} className="remove-wishlist"><i className="fal fa-times"></i></button></td>
                            <td className="product-thumbnail"><Link to={`/products/${product._id}`}><img src={product.image[0]} alt={product.title.en}/></Link></td>
                            <td className="product-title"><Link to={`/products/${product._id}`}>{product.title.en}</Link></td>
                            <td className="product-price" data-title="Price"><span className="currency-symbol">Rs: </span>{product.prices.price}</td>
                            <td className="product-stock-status" data-title="Status">
                            <div
  style={{
    color: product.stock > 0 ? 'green' : 'red', // Conditional styling
  }}
>
  {product.stock > 0 ? "In stock" : "Out of stock"}
</div>
</td><td className="product-add-cart">
<button 
    onClick={(e) => {
        e.preventDefault();
        if(product.stock <= 0) {
            toast.warning("Product is out of stock");
            return;
        }
        openModal(product);
    }} 
    className="axil-btn btn-outline"
>
    {isProductInCart(product._id) ? "Added to Cart" : "Add to Cart"}
</button>



</td>
                        </tr>
                    ))}
                    
                </tbody>
                
            </table>
        </div>
        
    </div>
</div>



            </div>
            {showModal && (
    <div className="modal-container">
        <div className="modal-content">
            <h2>Sizes available for the product</h2>
            <div className="size-buttons">
            {
  selectedProduct.attributeVariants
    .filter(variant => variant.quantity > 0)
    .map((variant, index) => (
      <button
        key={index}
        className={`btn m-2 ${selectedSize === variant.attr_name ? 'active' : ''}`}
        onClick={() => handleSizeClick(variant)}
      >
        {variant.attr_name}
      </button>
    ))
}


            </div>
            {/* Render error message */}
            {errorMessage && <div className="alert alert-warning mt-2">{errorMessage}</div>}
            <br />
            <button className="axil-btn btn-bg-primary" onClick={handleAddToCart}>Add to Cart</button>
            <button className="axil-btn btn-2  btn-secondary mt-3 " onClick={closeModal}>Close</button>
        </div>
    </div>
)}

            </div>
            
        </div>
        
    );
}

export default Wishlist;

