import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { createProduct } from "./Signin-Signup-pages/actions/productAction";
import OrderDetailsModal from "./OrderDetailsModal";
import "./myaccount.css"
import ReviewModal from './ReviewModal.js'; // Ensure the path is correct
import e from "cors";



const MyAccout = () => {
  const [userName, setUserName] = useState(''); // State to store user's name
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [wishlist, setWishlist] = useState([]);
  const [products, setProducts] = useState([]);
  const token = localStorage.getItem("token");
  const [orders, setOrders] = useState([]); // Add this state variable
  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [billingAddresses, setBillingAddresses] = useState([]);
  // Add state variables to manage editing and edited values
  const [editingAddressIndex, setEditingAddressIndex] = useState(null);
  const [editedAddress, setEditedAddress] = useState({});
  const [refreshAddresses, setRefreshAddresses] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedProductSizes, setSelectedProductSizes] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [cartData, setCartData] = useState([]);
  
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [selectedOrderForReview, setSelectedOrderForReview] = useState(null);
  const [selectedProductt, setSelectedProductt] = useState(null);
  const [showModall, setShowModall] = useState(false);

  const openReviewModal = (product,event) => {
    console.log("openReviewModal called");

    event.stopPropagation(); // Stop the event from bubbling up

    setSelectedProductt(product);
    setShowModal(true);
    setReviewProductStep(false);
  };
  

  const closeReviewModal = () => {
    setShowReviewModal(false);
    setSelectedOrderForReview(null);
  };

  const handleReviewSubmit = async (review) => {
    console.log("Submitting review for product ID:", selectedProductt.product_id);
    const productId = selectedProductt.product_id; 

  
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/prod/review`,
        {
          rating: review.rating,
          comment: review.notes,
          productId: productId,
          userName: userName // Include the user's name
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log('Response:', response.data); // Log the response for debugging
      toast.success("Review submitted successfully");
    } catch (error) {
      if (error.response) {


        // Check if the server responded with "Product already reviewed"
        if (error.response.data.message === "Product already reviewed") {
          toast.warn("Product already reviewed");
        } else {
          // Other server-side errors
          toast.error("Failed to submit review");
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Error Request:", error.request);
        toast.error("Review submission failed. No response from server");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error Message:", error.message);
        toast.error("An error occurred while submitting the review");
      }
    }
  };
  
const [reviewProductStep, setReviewProductStep] = useState(false);
const [orderToReview, setOrderToReview] = useState(null);

const openReviewProductStep = (order) => {
  setOrderToReview(order);
  if (order.cart && order.cart.length > 1) {
    setReviewProductStep(true);
  } else if (order.cart && order.cart.length === 1) {
    openReviewModal(order.cart[0]);
  }
};


  const iconStyle = {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    width: '110px', // Adjusted width
    height: '45px',
    backgroundColor: '#4CAF50',
    color: '#fff',

    borderRadius: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 10px',
    fontSize: '12px', // Adjust font size if needed
    fontWeight:"bold",
    cursor: 'pointer',
    boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
    zIndex: 1000,
    whiteSpace: 'normal', // Allow text wrapping
    textAlign: 'center', // Center align text
    overflow: 'hidden', // Prevent overflow
    textOverflow: 'ellipsis', // Add ellipsis if text is too long
    lineHeight: '1.2', // Adjust line height to reduce space between lines

  };
  
  

  const fetchProductDetails = async (productId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/product/${productId}`);
      return response.data.product;
    } catch (error) {
      console.error("Error fetching product details:", error);
      return null;
    }
  };
  const renderProductRow = (product, order) => {
    const imageUrl = product.image && product.image.length > 0 ? product.image[0] : 'default_image_url.jpg';

    return (
      <div key={product._id} style={{ marginTop: '-30px' }}> {/* Adjust top margin as needed */}
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', padding: '10px', borderBottom: '1px solid #ccc', marginBottom: "10px" }}>
          <img 
            src={imageUrl} 
            alt={product.title.en} 
            style={{ width: '100px', height: '100px', objectFit: 'cover',marginTop:"-35px" }}
          />
          <div style={{ flexGrow: 1 }}>
            <p style={{ margin: 0, padding: 0 }}>{product.title.en}</p>
            <p style={{ margin: 0, padding: 0 }}>Quantity: {product.quantity}</p>
            <p style={{ margin: 0, padding: 0 }}>Price: RS.{product.price}</p>
            <p style={{ margin: 0, padding: 0 }}>Status: {order.status}</p>
            {!showModal && (
              <button 
              onClick={(e) => openModall(order, e)} 
              className=" view-btn mt-4"
              style={{ padding: '5px 10px', fontSize: '12px', marginRight: '10px', width: '100px',background:"gray",borderRadius:"15px",fontWeight:"bold" }}>
              View Details
            </button>
            )}
            {order.status === 'Delivered' && (
              <button 
                onClick={(e) => openReviewModal(product, e)}
                className=" review-btn mt-4"
                style={{ padding: '5px 10px', fontSize: '12px', width: '100px',background:"gray",borderRadius:"15px",fontWeight:"bold" }}>
                Add Review
              </button>
            )}
          </div>
        </div>
      </div>
    );
};

  useEffect(() => {
    const fetchOrdersWithProductDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/allOrders`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        const fetchedOrders = response.data.orders;

        // Map over orders and fetch product details for each product in the cart
        const ordersWithProductDetails = await Promise.all(fetchedOrders.map(async (order) => {
          const cartWithDetails = await Promise.all(order.cart.map(async (cartItem) => {
            const productDetails = await fetchProductDetails(cartItem.product_id);
            return { ...cartItem, ...productDetails };
          }));

          return { ...order, cart: cartWithDetails };
        }));

        setOrders(ordersWithProductDetails);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrdersWithProductDetails();
  }, [token]);


  const type = "shipping";
  const openModall = (order, event) => {
    console.log("openModall called");
  
    event.stopPropagation(); // Stop the event from bubbling up
  
    setSelectedOrder(order);
    setShowModall(true);
    document.body.style.overflow = 'hidden'; // Disable scrolling
    
  };

  const closeModall = () => {
    setSelectedOrder(null);
    setShowModall(false); // This should close the modal
    document.body.style.overflow = 'unset'; // Re-enable scrolling


  };
  const openModal = (product) => {
    const size = products.find((p) => p._id === product._id);
    if (size && size.sizes) {
      setSelectedProductSizes(size.sizes);
    }
    setSelectedProduct(product);
    setShowModall(true);
  };

  const closeModal = () => {
    setShowModall(false);
    setSelectedProduct(null);
    setSelectedSize("");
  };

  const handleSizeClick = (size) => {
    setSelectedSize((prevSize) => (prevSize === size ? "" : size));
  };

  const handleAddToCart = () => {
    if (!selectedSize) {
      setErrorMessage("Please select a size");
      return;
    }
    setErrorMessage("");
    addProductToCart(selectedProduct._id, selectedSize);
    setCartData((prevCartData) => [...prevCartData, selectedProduct]);

    closeModal();
  };

  const handleAddressUpdate = async (index, type, operation = 'update') => {
    try {
        const config = {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        };

        await axios.put(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/updateAddress`, { address: editedAddress, type, index, operation }, config);

        if (operation === 'delete') {
            // Update the local state to remove the address
            setShippingAddresses(prevAddresses => {
                const updatedAddresses = [...prevAddresses];
                updatedAddresses.splice(index, 1);
                return updatedAddresses;
            });
        } else {
            // Reset the edit mode
            setEditingAddressIndex(null);
            
            // Update the local state to reflect the changes
            setShippingAddresses(prevAddresses => {
                const updatedAddresses = [...prevAddresses];
                updatedAddresses[index] = editedAddress;
                return updatedAddresses;
            });
        }

        // Reset editedAddress
        setEditedAddress({});

    } catch (error) {
        console.error(error);
        toast.error('Error updating address');
    }
};

const handleAddressDelete = async (index, type) => {
    try {
        const config = {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        };

        await axios.put(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/updateAddress`, 
        { action: 'delete', type, index }, config);

        // Update the local state to reflect the changes
        setShippingAddresses(prevAddresses => {
            const updatedAddresses = [...prevAddresses];
            updatedAddresses.splice(index, 1);
            return updatedAddresses;
        });
    } catch (error) {
        console.error(error);
        toast.error('Error deleting address');
    }
};



  useEffect(() => {
    // Fetch user's addresses
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/getAddresses`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        const { shipping_address, billing_address } = response.data.Addresses;

        if (shipping_address && shipping_address.length > 0) {
          setShippingAddresses(shipping_address);
        }

        if (billing_address && billing_address.length > 0) {
          setBillingAddresses(billing_address);
        }
      })
      .catch((error) => {
        console.error("Error fetching addresses:", error);
      });
  }, [token, refreshAddresses]);

  const isProductInCart = (productId) => {
    return cartData.some((item) => item._id === productId);
  };

  useEffect(() => {
    if (!token) {
      setLoading(false);
      return;
    }

    // Fetch user's orders
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/allOrders`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setOrders(response.data.orders);
      })
      .catch((error) => {
        console.error("Error fetching orders:", error);
      });
  }, []);

  useEffect(() => {
    if (!token) {
      setLoading(false);
      return;
    }

    axios
      .get(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/getwishlist`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setWishlist(response.data.wishlist);
      })
      .catch((error) => {
        console.error("Error fetching wishlist:", error);
      });
  }, []);

  useEffect(() => {
    if (wishlist.length) {
      Promise.all(
        wishlist.map((productId) =>
          axios.get(
            `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/product/${productId}`
          )
        )
      )
        .then((results) => {
          const updatedProducts = results.map((result) => {
            const product = result.data.product;
            let sizes = [];

            // Navigate through the nested variants structure
            if (result.data.variants) {
              const firstVariantKey = Object.keys(result.data.variants)[0]; // Get the first key
              const sizeObjects = result.data.variants[firstVariantKey];

              if (sizeObjects) {
                for (const key in sizeObjects) {
                  if (
                    sizeObjects.hasOwnProperty(key) &&
                    sizeObjects[key].attr_name
                  ) {
                    sizes.push(sizeObjects[key].attr_name);
                  }
                }
              }
            }

            return { ...product, sizes };
          });

          setProducts(updatedProducts);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching product details:", error);
        });
    } else {
      setLoading(false);
    }
  }, [wishlist]);

  function addProductToCart(productId) {
    const token = localStorage.getItem("token");

    if (!token) {
      toast.warning("User not logged in");
      return;
    }

    const payload = {
      size: selectedSize,
    };

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/addToCart/${productId}`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        // This will handle the success response
        if (response.data.success) {
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        // This will handle errors
        if (error.response) {
          if (
            error.response.status === 401 &&
            error.response.data.message === "User not logged in"
          ) {
            toast.warning("User not logged in");
          } else if (error.response.data.message) {
            toast.error(error.response.data.message);
          }
        } else {
          console.error("Error adding to cart:", error);
          toast.error("An error occurred. Please try again later.");
        }
      });
  }
  const addressStyle = {
    fontSize: "18px", // Set font size to 18px or any value you prefer
    lineHeight: "1.5", // Adjust line height for better readability
  };

  useEffect(() => {
    // Fetch the user data when the component loads
    const fetchUser = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };

        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/profile`,
          config
        );

        if (data.success) {
          setName(data.user.name);
          setUserName(data.user.name); // Set the user's name in state
        }
      } catch (error) {
        toast.error("Error fetching user data.");
      }
    };

    fetchUser();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Checking if the new password matches the confirmed password
    if (newPassword !== confirmNewPassword) {
      toast.error("New password and confirm password do not match.");
      return;
    }

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/updateprofile`,
        { name, oldPassword, newPassword },
        config
      );

      toast.success(data.message);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString([], options);
  }

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      setLoading(false);
      toast.error("No token found in local storage. User is not logged in.");
      return;
    }

    axios
      .get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/profile`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.data.success) {
          setUser(response.data.user);
        } else {
          toast.error(response.data.message || "Error fetching profile.");
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Error while fetching user profile.");
      });
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    setUser(null);
    toast.success("Logged out successfully.");
  };

  if (loading) return <div>Loading...</div>;
  if (!token)
    return (
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        User not logged in
      </div>
    );
  return (
    <>
      {user && (
        <main className="main-wrapper">
          <div className="axil-dashboard-area axil-section-gap">
            <div className="container">
              <div className="axil-dashboard-warp">
                <div className="axil-dashboard-author">
                  <div className="media">
                    <div className="media-body">
                      <h5 className="title mb-0">Hello {user.name}</h5>
                      <span className="joining-date">
                        Dazzle Member Since {formatDate(user.createdDate)}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-3 col-md-4">
                    <aside className="axil-dashboard-aside">
                      <nav className="axil-dashboard-nav">
                        <div className="nav nav-tabs" role="tablist">
                          <a
                            className="nav-item nav-link active"
                            data-bs-toggle="tab"
                            href="#nav-orders"
                            role="tab"
                            aria-selected="false"
                          >
                            <i className="fas fa-shopping-basket"></i>Orders
                          </a>
                          <a
                            className="nav-item nav-link"
                            data-bs-toggle="tab"
                            href="#nav-downloads"
                            role="tab"
                            aria-selected="false"
                          >
                            <i class="flaticon-heart"></i>Wishlist
                          </a>
                          <a
                            className="nav-item nav-link"
                            data-bs-toggle="tab"
                            href="#nav-address"
                            role="tab"
                            aria-selected="false"
                          >
                            <i className="fas fa-home"></i>Addresses
                          </a>
                          <a
                            className="nav-item nav-link"
                            data-bs-toggle="tab"
                            href="#nav-account"
                            role="tab"
                            aria-selected="false"
                          >
                            <i className="fas fa-user"></i>Account Details
                          </a>
                        </div>
                      </nav>
                    </aside>
                  </div>
                  <div className="col-xl-9 col-md-8">
                    <div className="tab-content">
                      <div
                        className="tab-pane fade show active"
                        id="nav-orders"
                        role="tabpanel"
                      >
                       <div>
      {orders.map((order) => (
        <div key={order._id}>
          <h5>Order ID: {order._id}</h5>
          {order.cart.map((product) => renderProductRow(product, order))}
          {/* Additional order details can be rendered here */}
        </div>
        
      ))}
    </div>
    {selectedOrder && (
          <OrderDetailsModal
          isOpen={showModall} // Ensure this is managed correctly
          closeModal={closeModall}
          order={selectedOrder}
      />
        )}
    
 


                      </div>
                    
                      <div
                        className="tab-pane fade"
                        id="nav-downloads"
                        role="tabpanel"
                      >
                        {products.length ? (
                          <div className="axil-dashboard-order">
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Product</th>
                                    <th scope="col">Title</th>
                                    <th scope="col">Unit Price</th>
                                    <th scope="col">Stock Status</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {products.map((product) => (
                                    <tr key={product._id}>
                                      <td className="product-thumbnail">
                                        <Link to={`/products/${product._id}`}>
                                          <img
                                            src={product.image[0]}
                                            alt={product.title.en}
                                          />
                                        </Link>
                                      </td>
                                      <td className="product-title">
                                        <Link to={`/products/${product._id}`}>
                                          {product.title.en}
                                        </Link>
                                      </td>
                                      <td>
                                        <span className="currency-symbol">
                                          ₹
                                        </span>
                                        {product.prices.price}
                                      </td>
                                      <td>
                                        <a className="text-danger">
                                          {product.stock > 0
                                            ? "In stock"
                                            : "Out of stock"}
                                        </a>{" "}
                                      </td>
                                      <td>
                                        <button
                                          id="add-to-cart"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            openModal(product);
                                          }}
                                          className="axil-btn btn-outline"
                                        >
                                          {isProductInCart(product._id)
                                            ? "Added to Cart"
                                            : "Add to Cart"}
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                                {showModal && (
                                  <div className="modal-container">
                                    <div className="modal-content">
                                      <h2>Sizes available for the product</h2>
                                      <div className="size-buttons">
                                        {selectedProductSizes.map((size) => (
                                          <button
                                            key={size}
                                            className={`btn m-2 ${
                                              selectedSize === size
                                                ? "active"
                                                : ""
                                            }`}
                                            onClick={() =>
                                              handleSizeClick(size)
                                            }
                                          >
                                            {size}
                                          </button>
                                        ))}
                                      </div>
                                      {errorMessage && (
                                        <div className="alert alert-warning mt-2">
                                          {errorMessage}
                                        </div>
                                      )}
                                      <br />
                                      <button
                                        className="axil-btn btn-bg-primary"
                                        onClick={handleAddToCart}
                                      >
                                        Add to Cart
                                      </button>
                                      <button
                                        className="btn btn-secondary mt-3 ml-2"
                                        onClick={closeModal}
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </table>
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{ textAlign: "center", marginTop: "20px" }}
                          >
                            No items in your wishlist
                          </div>
                        )}
                      </div>

                      <div
                        className="tab-pane fade"
                        id="nav-address"
                        role="tabpanel"
                      >
                        <div className="axil-dashboard-address">
                          <p className="notice-text">
                            The following addresses will be used on the checkout
                            page by default.
                          </p>
                          <div className="row row--30">
                            <div className="col-lg-6">
                              <div className="address-info mb--40">
                                <div className="addrss-header d-flex align-items-center justify-content-between">
                                  <h4 className="title mb-0">
                                    Shipping Address
                                  </h4>
                                </div>
                                <ul className="address-details">
                                  {shippingAddresses.map((address, index) => (
                                    <li key={index} style={addressStyle}>
                                      {editingAddressIndex === index ? (
                                        <>
                                          <input
                                            type="text"
                                            className="form-control input-btn"
                                            defaultValue={address.firstName}
                                            onChange={(e) =>
                                              setEditedAddress({
                                                ...editedAddress,
                                                firstName: e.target.value,
                                              })
                                            }
                                            placeholder="First Name"
                                          />
                                          <input
                                            type="text"
                                            className="form-control input-btn"
                                            defaultValue={address.lastName}
                                            onChange={(e) =>
                                              setEditedAddress({
                                                ...editedAddress,
                                                lastName: e.target.value,
                                              })
                                            }
                                            placeholder="Last Name"
                                          />
                                          <input
                                            type="text"
                                            className="form-control input-btn"
                                            defaultValue={address.phone}
                                            onChange={(e) =>
                                              setEditedAddress({
                                                ...editedAddress,
                                                phone: e.target.value,
                                              })
                                            }
                                            placeholder="Phone"
                                          />
                                          <input
                                            type="text"
                                            style={{
                                              fontSize: "18px",
                                              height: "30px",
                                            }} // Apply inline styles here
                                            className="form-control input-btn"
                                            defaultValue={address.address}
                                            onChange={(e) =>
                                              setEditedAddress({
                                                ...editedAddress,
                                                address: e.target.value,
                                              })
                                            }
                                          />
                                          <input
                                            type="text"
                                            style={{
                                              fontSize: "18px",
                                              height: "30px",
                                            }} // Apply inline styles here
                                            className="form-control input-btn"
                                            defaultValue={address.city}
                                            onChange={(e) =>
                                              setEditedAddress({
                                                ...editedAddress,
                                                city: e.target.value,
                                              })
                                            }
                                          />
                                          <input
                                            type="text"
                                            style={{
                                              fontSize: "18px",
                                              height: "30px",
                                            }} // Apply inline styles here
                                            className="form-control input-btn"
                                            defaultValue={address.country}
                                            onChange={(e) =>
                                              setEditedAddress({
                                                ...editedAddress,
                                                country: e.target.value,
                                              })
                                            }
                                          />
                                          <input
                                            type="text"
                                            style={{
                                              fontSize: "18px",
                                              height: "30px",
                                            }} // Apply inline styles here
                                            className="form-control input-btn"
                                            defaultValue={address.zipCode}
                                            onChange={(e) =>
                                              setEditedAddress({
                                                ...editedAddress,
                                                zipCode: e.target.value,
                                              })
                                            }
                                          />
                                          <button
                                            className="axil-btn btn-sm-primary btn-hover"
                                            onClick={() =>
                                              handleAddressUpdate(
                                                index,
                                                "shipping"
                                              )
                                            }
                                          >
                                            Apply
                                          </button>
                                          <br />
                                          <br />
                                          <button
                                            className="axil-btn btn-sm-secondary btn-hover"
                                            onClick={() =>
                                              setEditingAddressIndex(null)
                                            }
                                          >
                                            Cancel
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          Name: {address.firstName}{" "} &nbsp;
                                          {address.lastName} <br />
                                          Phone: {address.phone} <br />
                                          Address: {address.address}, <br />
                                          {address.city}, <br />
                                          {address.country}, <br />
                                          {address.zipCode}
                                          <button
                                            className="axil-btn btn-sm-primary btn-hover"
                                            onClick={() => {
                                              setEditingAddressIndex(index);
                                              setEditedAddress(address); // Initialize editedAddress with the current address
                                            }}
                                          >
                                            Edit
                                          </button>
                                          <button onClick={(e) => {
    e.preventDefault();
    handleAddressDelete(index, type);
}}>
    Delete
</button>
                                        </>
                                      )}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="nav-account"
                        role="tabpanel"
                      >
                        <div className="col-lg-9">
                          <div className="axil-dashboard-account">
                            <form
                              className="account-details-form"
                              onSubmit={handleSubmit}
                            >
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <label>Name</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={name}
                                      onChange={(e) => setName(e.target.value)}
                                    />
                                  </div>
                                </div>
                                {/* <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>Last Name</label>
                                                            <input type="text" className="form-control" value="Mario"/>
                                                        </div>
                                                    </div> */}
                                <div className="col-12">
                                  <div className="form-group mb--40">
                                    <p className="b3 mt--10">
                                      This will be how your name will be
                                      displayed in the account section and in
                                      reviews
                                    </p>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <h5 className="title">Password Change</h5>
                                  <div className="form-group">
                                    <label>Password</label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      value={oldPassword}
                                      onChange={(e) =>
                                        setOldPassword(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label>New Password</label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      value={newPassword}
                                      onChange={(e) =>
                                        setNewPassword(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label>Confirm New Password</label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      value={confirmNewPassword}
                                      onChange={(e) =>
                                        setConfirmNewPassword(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="form-group mb--0">
                                    <input
                                      type="submit"
                                      className="axil-btn"
                                      value="Save Changes"
                                    />
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {reviewProductStep && orderToReview && orderToReview.cart && (
  <div className="modal">
    <div className="modal-content">
      <span className="close-btn" onClick={() => setReviewProductStep(false)}>&times;</span>
      <h3>Select a product to review</h3>
      {orderToReview.cart.map((product) => (
        <div key={product.product_id} className="product-item">
          <span>{product.title}</span>
          <button onClick={() => openReviewModal(product,e)}>Review</button>
        </div>
      ))}
    </div>
  </div>
)}




{showModal && (
  <ReviewModal
    isOpen={showModal}
    product={selectedProductt}
    closeModal={() => setShowModal(false)}
    submitReview={handleReviewSubmit}
  />
)}
          <div style={iconStyle}>
  <span onClick={() => window.open("https://tawk.to/dazzle", "_blank")}>
    &#128172; {/* Icon */}
  </span>
  <span style={{ marginLeft: '10px', fontSize:'15px',color:"black" }} onClick={() => window.open("https://tawk.to/dazzle", "_blank")}>
    Exchange Support {/* Text */}
  </span>
</div>


        </main>
      )}
    </>
  );
};

export default MyAccout;
