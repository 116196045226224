import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import metaData from './meta.json'; // Import the metadata JSON file
import SimilarProductCarousel from "../pages-inner-components/SimilarProductsCarousel";
const Cart = () => {
  useEffect(() => {
    if (metaData && metaData[6] && metaData[6].components && metaData[6].components.Cart) {
      const { title, description } = metaData[6].components.Cart;
      document.title = title;
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', description);
      }
    }
  }, []);
  const [cart, setCart] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");
  const [cartItems, setCartItems] = useState([]);
  const [quantity, setQuantity] = useState(1); 
  const [subtotal, setSubtotal] = useState(0);
  const [reloadKey, setReloadKey] = useState(0);
  const debouncedUpdateCartInDB = debounce(() => updateCartInDB(), 500);

  function debounce(func, wait, immediate) {
    let timeout;
    return function() {
      const context = this, args = arguments;
      const later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };
  
  function shuffleArray(array) {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  }
  
  // Fetch the relatedProducts somewhere in your code
  useEffect(() => {
    // Fetch related products here and update setRelatedProducts
  }, [reloadKey]);
  
  
  const handleProductClick = (productId) => {
    // Navigate to the new product page
    // You can use react-router's navigate or history.push here

    // Increment the key to force remount of "Similar Products" only
    setReloadKey(prevKey => prevKey + 1);
  };

  const fetchProductsFromEndpoint = async (endpoint) => {
    const products = [];
    let page = 1;
    let hasMore = true;

    while (hasMore) {
        const url = `${process.env.REACT_APP_BACKEND_ENDPOINT}${endpoint}?page=${page}`;
        const response = await fetch(url);
        const data = await response.json();

        products.push(...data.products);

        page++;
        hasMore = data.products.length > 0;
    }

    return products;
};

const fetchAllProducts = async () => {
  const endpoints = ['/api/v1/tshirts', '/api/v1/lowers', '/api/v1/shorts'];
  let allProducts = [];

  for (let endpoint of endpoints) {
      const products = await fetchProductsFromEndpoint(endpoint);
      allProducts.push(...products);
  }

  return shuffleArray(allProducts); // Return shuffled products
};


const [productss, setProductss] = useState([]);

useEffect(() => {
  const getProducts = async () => {
      const allProducts = await fetchAllProducts();
      setProductss(allProducts.slice(0, 12)); // Only get the first 12 shuffled products
  };
  
  getProducts();
}, []);

const incrementQuantity = (e, productId, size, sizeId) => {
  e.preventDefault(); // Prevent the default action

  const updatedProducts = [...products];
  const productIndex = updatedProducts.findIndex(
    product => product._id === productId && product.size === size
  );

  if (productIndex !== -1) {
    if (updatedProducts[productIndex].quantity < 5) {
      updatedProducts[productIndex].quantity++;
      updatedProducts[productIndex].sizeId = sizeId; // Update the sizeId
    } else {
      toast.info("Maximum quantity of 5 has been reached for this product.");
    }
    setProducts(updatedProducts);
    updateSubtotal(updatedProducts);
    debouncedUpdateCartInDB();
  }
};



const decrementQuantity = (e, productId, size) => {
  e.preventDefault(); // Prevent the default action

  const updatedProducts = [...products];
  const productIndex = updatedProducts.findIndex(
    (product) => product._id === productId && product.size === size
  );

  if (productIndex !== -1) {
    if (updatedProducts[productIndex].quantity > 1) {
      updatedProducts[productIndex].quantity--;
      setProducts(updatedProducts);
      updateSubtotal(updatedProducts);

      debouncedUpdateCartInDB();
    } else {
      removeFromCart(productId, size);
    }
  }
};

  const updateSubtotal = (updatedProducts) => {
    const cartSubtotal = updatedProducts.reduce((total, product) => {
      return total + product.prices.price * product.quantity;
    }, 0);
    setSubtotal(cartSubtotal);
  };

  const fetchCartData = async () => {
    try {
        if (!token) {
            setLoading(false);
            return;
        }

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/getCart`, {
            headers: { Authorization: `Bearer ${token}` },
        });

        const cartData = response.data.cart;

        if (cartData.length) {
            const productRequests = cartData.map((item) =>
                axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/product/${item._id}`)
            );

            const productResponses = await Promise.all(productRequests);

            const updatedProducts = productResponses.map((result, index) => {
                const product = result.data.product;

                const selectedSize = cartData[index].size;
                let sizePrice = product.prices.price; // Default to product price
                let sizeId = null; // Variable to store the size ID

                // Extract size-specific price and ID
                const productColor = Object.keys(result.data.variants)[0];
                if (result.data.variants[productColor]) {
                    const sizeInfo = Object.values(result.data.variants[productColor]).find(variant => variant.attr_name === selectedSize);
                    if (sizeInfo) {
                        if (sizeInfo.price) {
                            sizePrice = sizeInfo.price;
                        }
                        sizeId = sizeInfo.size_id; // Extracting the size_id
                        console.log(`Size: ${selectedSize}, Size ID: ${sizeId}`);
                    }
                }

                return {
                    ...product,
                    quantity: cartData[index].quantity,
                    size: selectedSize,
                    sizePrice: sizePrice,
                    sizeId: sizeId, // Store the size ID
                };
            });

            setProducts(updatedProducts);

            const cartSubtotal = updatedProducts.reduce((total, product) => {
                return total + product.sizePrice * product.quantity;
            }, 0);
            setSubtotal(cartSubtotal);
        }

        setLoading(false);
    } catch (error) {
        console.error("Error fetching cart:", error);
    }
};


  // Call the fetchCartData function when the component mounts
  useEffect(() => {
    fetchCartData();
  }, []);
  const removeFromCart = (productId, size) => {
    axios
      .delete(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/removefromcart/${productId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // Find the product that matches the given productId and size
        const productToRemoveIndex = products.findIndex(
          (product) => product._id === productId && product.size === size
        );
        if (productToRemoveIndex !== -1) {
          const updatedProducts = [...products];
          updatedProducts.splice(productToRemoveIndex, 1);
          setProducts(updatedProducts);
          updateSubtotal(updatedProducts);
        }
        toast.success("Product removed from Cart.");
      })
      .catch((error) => {
        console.error("Error removing product from cart:", error);
      });
  };

  const updateCartInDB = async () => {
    setLoading(true);
  
    try {
      const updatedCart = products.map(product => ({
        _id: product._id,
        size: product.size,
        quantity: product.quantity,
        sizeId: product.sizeId, // Include the sizeId
      }));
  
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/updatecart`,
        { cart: updatedCart },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // Handle the error based on the response from the backend
      // For example, if the stock is insufficient
      if (error.response && error.response.status === 400 && error.response.data.productId) {
        fetchCartData()
        toast.error(error.response.data.message);
        setProducts(prevProducts => prevProducts.map(product => {
          if (product._id === error.response.data.productId) {
            return { ...product, quantity: error.response.data.maxStock };
          }
          return product;
        }));
      } else {
        toast.error("Failed to update cart. Please try again.");
      }
    }
  };
  


  

  if (loading) return <div>Loading...</div>;
  if (!token)
    return (
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        User not logged in
      </div>
    );
  if (!products.length)
    return (
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        No items in your Cart
      </div>
    );

  return (
    <div>
      <main className="main-wrapper">
        <div className="axil-product-cart-area axil-section-gap">
          <div className="container">
            <div className="axil-product-cart-wrap">
              <div className="product-table-heading">
                <h4 className="title">Your Cart</h4>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="table-responsive">
                    <table className="table axil-product-table axil-cart-table mb--40">
                      <thead>
                        <tr>
                          <th scope="col" className="product-remove"></th>
                          <th scope="col" className="product-thumbnail">
                            Product
                          </th>
                          <th scope="col" className="product-title"></th>
                          <th scope="col" className="product-size">
                            Size
                          </th>

                          <th scope="col" className="product-price">
                            Price
                          </th>
                          <th scope="col" className="product-quantity">
                            Quantity
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {products.map((product) => (
                          <tr key={product._id}>
                            <td className="product-remove">
                              <button
                                onClick={() =>
                                  removeFromCart(product._id, product.size)
                                }
                                className="remove-wishlist"
                              >
                                <i className="fal fa-times"></i>
                              </button>
                            </td>{" "}
                            <td className="product-thumbnail">
                              <img
                                src={product.image[0]}
                                alt={product.title.en}
                              />
                            </td>
                            <td className="product-title">
                              {product.title.en}
                            </td>
                            <td className="product-size">{product.size}</td>
                            <td className="product-price" data-title="Price">
  <span className="currency-symbol">Rs. </span>
  {product.sizePrice}
</td>

                            <td className="product-quantity" data-title="Qty">
                              <div
                                className="pro-qty"
                                style={{ display: "flex" }}
                              >
                                <button
  onClick={(e) => decrementQuantity(e, product._id, product.size)}
  className="dec qtybutton"
>
  -
</button>

                                <input
  type="number"
  className="quantity-input"
  value={product.quantity}
  onChange={(e) => {
    let newQuantity = parseInt(e.target.value);
    if (!isNaN(newQuantity)) {
      if (newQuantity > 5) {
        newQuantity = 5; // Reset to 5 if more than 5
        toast.info("Maximum quantity of 5 has been reached for this product.");
      } else if (newQuantity < 1) {
        newQuantity = 1; // Minimum quantity is 1
      }
      const updatedProducts = [...products];
      const productIndex = updatedProducts.findIndex(
        (p) => p._id === product._id && p.size === product.size
      );
      if (productIndex !== -1) {
        updatedProducts[productIndex].quantity = newQuantity;
        setProducts(updatedProducts);
        updateSubtotal(updatedProducts);
        debouncedUpdateCartInDB();
      }
    }
  }}
/>

<button
  onClick={(e) => incrementQuantity(e, product._id, product.size, product.sizeId)}
  className="inc qtybutton"
>
  +
</button>


                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="axil-order-summery order-checkout-summery">
                        <h5 className="title mb--20">Your Order</h5>
                        <div className="summery-table-wrap">
                          <table className="table summery-table">
                            <thead>
                              <tr>
                                <th>Product</th>
                                <th>Price</th>
                              </tr>
                            </thead>
                            <tbody>
                              {products.map((product) => (
                                <tr key={product._id} className="order-product">
                                  <td
                                    style={{ display: "none" }}
                                    id="product_id"
                                  >
                                    {product._id}
                                  </td>
                                  <td id="product_quantity">
                                    {product.title.en}*
                                    <span className="quantity">
                                      {product.quantity}
                                    </span>
                                  </td>
                                  <td id="product_price">Rs.{product.sizePrice * product.quantity}</td>

                                </tr>
                              ))}
                              <tr className="order-subtotal">
                                <td>
                                  <b>Sub-total</b>
                                </td>
                                <td>Rs.{subtotal}</td>
                              </tr>
                              {/* <tr className="order-shipping">
                                <td colspan="2">
                                  <div className="shipping-amount">
                                    <span className="title">Discount</span>
                                    <span className="amount">Rs: 0</span>
                                  </div>
                                </td>
                              </tr> */}
                              <tr className="order-shipping">
                                <td colspan="2">
                                  <div className="shipping-amount">
                                    <span className="title">Standard Delivery Charges</span>
                                    <span className="amount">Rs: 75.00</span>
                                  </div>
                                </td>
                              </tr>
                          
                              <tr className="order-total">
                                <td>Total AMOUNT</td>
                                <td className="order-total-amount">
                                  Rs.{subtotal - 0 + 75}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <Link
                          to={"/checkout"}
                          className="axil-btn add-cart-btn-custom checkout-btn"
                        >
                          Proceed to Checkout
                        </Link>
                      </div>
                    </div>
                    
                  </div>
                  

                </div>
              </div>
              <div className="suggested-products-section">
  {productss.length > 0 && (
      <>
          <h2 className="text-center">Suggested Products</h2>
          <SimilarProductCarousel products={productss} />
      </>
  )}
</div>

            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Cart;
