import React from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    // Ensure the page is rendered at the top on route changes
    window.scrollTo({ top: 0, behavior: 'auto' }); // Use 'auto' behavior for instant scrolling
  }, [pathname]);

  return null; // This component doesn't render anything
}

export default ScrollToTop;
