import React, { useState } from 'react';
import logo from '../../assests/dazzle-images/logo.png'
import { useEffect } from 'react'; // Only import useEffect from 'react'

import metaData from '../meta.json'; // Import the metadata JSON file
import axios from 'axios';
import { Link, useNavigate, useLocation } from 'react-router-dom'; // MODIFIED
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SignIn = () => {
    useEffect(() => {
        if (metaData && metaData[7] && metaData[7].components && metaData[7].components.SignIn) {
          const { title, description } = metaData[7].components.SignIn;
          document.title = title;
          const metaDescription = document.querySelector('meta[name="description"]');
          if (metaDescription) {
            metaDescription.setAttribute('content', description);
          }
        }
      }, []);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();  // NEW
  const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [isOtpStage, setIsOtpStage] = useState(false);
    const [isOtpLogin, setIsOtpLogin] = useState(true);

    const handleRequestOtp = (event) => {
        event.preventDefault();
        
        // Assuming phoneNumber is a state variable or is extracted from an input field
        // We're ensuring only 10 digits are sent without the "91" prefix
        const formattedPhoneNumber = phoneNumber;

    axios
    .post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/sendLoginOtp`, { phoneNumber: formattedPhoneNumber })
            .then(response => {
                if (response.data.success) {
                    toast.success('OTP sent successfully!');
                    setIsOtpStage(true);
                } else {
                    toast.error(response.data.message);  // This line will display the response message
                }
            })
            .catch(error => {
                // Handle errors
                if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message); 
                } else {
                    toast.error('Unexpected error occurred.');
                }
            });
    };
    
    
    
    
    
    const handleLoginWithOtp = (event) => {
        event.preventDefault();

        axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/verifyLoginOtp`, { phoneNumber, otp })
            .then(response => {
                if(response.data.success) {
                    localStorage.setItem('token', response.data.token);
                    toast.success(response.data.message);
                    const redirectURL = localStorage.getItem('redirectAfterLogin');
                    if (redirectURL) {
                      window.location = redirectURL;
                      localStorage.removeItem('redirectAfterLogin'); // Remove the saved URL
                  } else {
                      window.location = '/';
                  }                } else {
                    toast.error('Invalid OTP. Please try again.');
                }
            })
            .catch(error => {
                // Handle errors
                toast.error('Error verifying OTP.');
            });
    };

  const handleSubmit = (event) => {
      event.preventDefault();

      axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/login`, { email, password })
          .then((response) => {
              if (response.data.success) {
                  localStorage.setItem('token', response.data.token);
                  toast.success('Login successful!');
                  console.log("Redirect URL:", localStorage.getItem('redirectAfterLogin'));

                  const redirectURL = localStorage.getItem('redirectAfterLogin');
                  if (redirectURL) {
                    window.location = redirectURL;
                    localStorage.removeItem('redirectAfterLogin'); // Remove the saved URL
                } else {
                    window.location = '/';
                }
              } else {
                  toast.error('Email Not Registered Or Invalid Password! Please Register Or Login With Correct Password!');
              }
          })
          .catch((error) => {
        console.log(error);

        // Checking if there's a response from the server, if yes then display that error.
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error('An error occurred during login.');
        }
      });
};


  return (
    <>
      <div className="axil-signin-area">


<div className="signin-header">
    <div className="row align-items-center">
    <div className="col-xl-4 col-sm-6">
      
      </div>
      <div className="col-md-2 d-lg-block ">
      <Link to={"/"} className="site-logo mx-5"><img src={logo} alt="logo"/></Link>
      </div>
        <div className="col-xl-6 col-lg-4 col-sm-6">
            <div className="singin-header-btn">
                <p>Not a member?</p>
                <Link to="/signup" className="axil-btn btn-bg-secondary sign-up-btn">Sign Up Now</Link>
            </div>
        </div>
    </div>
</div>


<div className="row">
    <div className="col-xl-4 col-lg-6">
        <div className="axil-signin-banner bg_image bg_image--9">
          
        </div>
    </div>
    <div className="col-lg-6 offset-xl-2">
        <div className="axil-signin-form-wrap">
            <div className="axil-signin-form">
                <h3 className="title">Sign in to Dazzle</h3>
                <p className="b2 mb--55">Enter your detail below</p>
                <form className="singin-form" onSubmit={isOtpLogin ? (isOtpStage ? handleLoginWithOtp : handleRequestOtp) : handleSubmit}>

    {/* Show the phone number and OTP fields only if isOtpLogin is true */}
    {isOtpLogin && (
        <>
            <div className="form-group">
                <label>Phone Number</label>
                <input
                    type="text"
                    className="form-control"
                    value={phoneNumber}
                    onChange={(event) => setPhoneNumber(event.target.value)}
                    required
                />
            </div>
            {isOtpStage && (
                <div className="form-group">
                    <label>Enter OTP</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        required
                    />
                </div>
            )}
        </>
    )}

    {/* Show the email and password fields only if isOtpLogin is false */}
    {!isOtpLogin && (
        <>
            <div className="form-group">
                <label>Email</label>
                <input
                    type="email"
                    className="form-control"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    required
                />
            </div>
            <div className="form-group">
                <label>Password</label>
                <input
                    type="password"
                    className="form-control"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    required
                />
            </div>
            <Link to="/forgotpassword" style={{ textDecoration: 'underline', color: 'orange', marginBottom: '10px', display: 'block' }}>
                Forgot Password?
            </Link>
        </>
    )}

    <button type="submit" className="axil-btn btn-bg-primary submit-btn">
        {isOtpLogin ? (isOtpStage ? "Verify OTP" : "Request OTP") : "Login"}
    </button>
    <Link to="#" onClick={() => setIsOtpLogin(!isOtpLogin)} style={{ textDecoration: 'underline', color: 'orange', marginTop: '10px', display: 'block' }}>
        {isOtpLogin ? "Login with Email/Password" : "Login with Phone Number"}
    </Link>
</form>



            </div>
        </div>
    </div>
</div>
</div>
<ToastContainer position="top-center" autoClose={5000}     style={{ width: 'max-content' }}  />

    </>
  )
}

export default SignIn