import React from 'react'
import img1 from '../assests/dazzle-images/mini-1.png'
import img2 from '../assests/dazzle-images/mini-2.png'

import img3 from '../assests/dazzle-images/mini-3.png'
import img4 from '../assests/dazzle-images/mini-4.jpg'
import topchoseimg from '../assests/dazzle-images/top-choice.gif'
import { Link } from 'react-router-dom'
const TopChoiceComponent = () => {
  return (
    <>
 <div className="product-collection-area bg-lighter axil-section-gapcommon ">
            <div className="container-fluid">
              
                <div className="row">
                    <div className="col-xl-7 ">
                        <div className="product-collection product-collection-two" >
                           
                           <img src={topchoseimg} className='top-choices-coustm' width="100%" height="100%" alt=""/>
                        
                
                        </div>
                    </div>
                    <div className="col-xl-5 ">
                        <div className="row row--15-2">
                            <div className="col-md-6 col-xl-4-2 style-class">
                              


                                <div className="thumbnail">
                                         <Link to={"/tshirts"} className="">
                                         <img src={img1} className='image-coustm-size' width="100%" alt="Product"/>
                                         </Link>
                           </div>
                           <div className="product-content ">
                                 <div className="inner">
                                     <h5 className="title tittle-bt">T-Shirts</h5>
               
                                  </div>
                          </div>

                                   
                                
                                   
                             
                            </div>
                            <div className="col-md-6 col-xl-4-2 style-class">
    
                            <div className="thumbnail">
                                         <Link to={"/lowers"} className="">
                                         <img src={img2} className='image-coustm-size' width="100%" alt="Product"/>
                                         </Link>
                           </div>
                           <div className="product-content ">
                                 <div className="inner">
                                     <h5 className="title tittle-bt">Lowers</h5>
               
                                  </div>
                          </div>

                               
                            </div>
                            <div className="col-md-6 col-xl-4-2 style-class">
                            <div className="thumbnail">
                                         <Link to={"/shorts"} className="">
                                         <img src={img3} className='image-coustm-size' width="100%" alt="Product"/>
                                         </Link>
                           </div>
                           <div className="product-content ">
                                 <div className="inner">
                                     <h5 className="title tittle-bt">Shorts</h5>
               
                                  </div>
                          </div>

                              
                            </div>
                            <div className="col-md-6 col-xl-4-2 style-class">
                            <div className="thumbnail">
                                         <Link to={"/lowers"} className="">
                                             <img src={img4} className='image-coustm-size' width="100%" style={{borderRadius: '5px',}} alt="Product"/>
                                         </Link>
                           </div>
                           <div className="product-content ">
                                 <div className="inner">
                                     <h5 className="title tittle-bt">Tracks</h5>
               
                                  </div>
                          </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
  )
};

export default TopChoiceComponent