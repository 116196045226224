import React, { useEffect, useState } from 'react';
import ordersucess from '../assests/dazzle-images/nutmeg.gif';
import orderfail from '../assests/dazzle-images/delete.gif';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Cookie from 'js-cookie';

const OrderSuccessfull = () => {
  const location = useLocation();
  const [orderStatus, setOrderStatus] = useState(null); // State to hold the order status
  const [parsedData, setParsedData] = useState(null); // State to hold parsedData
  const token = localStorage.getItem('token');
  const orderData = JSON.parse(Cookie.get('checkoutDetails') || '{}');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const data = params.get('data');
    if (data) {
      const decodedData = JSON.parse(decodeURIComponent(data));
      const parsedData = Object.fromEntries(new URLSearchParams(decodedData));
      setParsedData(parsedData);
      setOrderStatus(parsedData.order_status);
      window.history.replaceState({}, document.title, '/ordersuccess');
    }
  }, [location]);

  useEffect(() => {
    // Check if orderStatus is not null and parsedData is available before sending the request
    if (orderStatus !== null && parsedData !== null) {
      // Update the orderData object to include the order status
      orderData.status = orderStatus === 'Success' ? 'Processing' : 'Failed';
      // Get the payment_mode from parsedData and assign it to paymentMethod in orderData
      orderData.paymentMethod = parsedData.payment_mode;

      // Map billing details from parsedData to orderData.user_info.billing_address
      orderData.user_info.billing_address.address = parsedData.billing_address;
      orderData.user_info.billing_address.city = parsedData.billing_city;
      orderData.user_info.billing_address.country = parsedData.billing_country;
      orderData.user_info.billing_address.email = parsedData.billing_email;
      orderData.user_info.billing_address.firstName = parsedData.billing_name;
      orderData.user_info.billing_address.lastName = ''; // You can add last name from parsedData if available
      orderData.user_info.billing_address.phone = parsedData.billing_tel;
      orderData.user_info.billing_address.zipCode = parsedData.billing_zip;

      const processOrder = async () => {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/placeorder`,
            orderData,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          if (!response.data.success) {
            console.error('Error placing order:', response.data.message);
          }
        } catch (error) {
          console.error('Error placing order:', error);
        }
      };

      // Always send the request to the endpoint
      processOrder();
    }
  }, [orderStatus, orderData, token, parsedData]);

  const renderShippingDetails = (details) => {
    return (
      <div>
        <strong>Shipping to:</strong>
        <p style={{ margin: '5px 0' }}>{details.firstName} {details.lastName}</p>
        <p style={{ margin: '5px 0' }}>{details.address}</p>
        <p style={{ margin: '5px 0' }}>{details.city}, {details.zipCode}</p>
        <p style={{ margin: '5px 0' }}>{details.country}</p>
        <p style={{ margin: '5px 0' }}>Email: {details.email}</p>
        <p style={{ margin: '5px 0' }}>Phone: {details.phone}</p>
      </div>
    );
  };

  return (
    <div className='container'>
      <div className='row py-5'>
        <div className='col-md-4'></div>
        <div className='col-md-4'>
          {orderStatus === 'Success' ? (
            <div className='card2 ' style={{ width: '100%' }}>
              <img src={ordersucess} className='card-img-top' alt='...' />
              <div className='card-body justify-content-center'>
                <h5 className='card-title py-3' style={{ color: '#12D76E' }}>
                  Your Order Placed Successfully
                </h5>
              </div>
              {/* Adding a section to print out the order details when the order is successful */}
              <div className='mt-4'>{orderData && orderData.user_info && renderShippingDetails(orderData.user_info.shipping_address)}</div>
            </div>
          ) : (
            <div className='card2 ' style={{ width: '100%' }}>
              <img src={orderfail} className='card-img-top' alt='...' />
              <div className='card-body justify-content-center'>
                <h5 className='card-title py-3' style={{ color: '#FF0000' }}>
                  Payment failed. Order was not placed.
                </h5>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-4'></div>
      </div>
    </div>
  );
};

export default OrderSuccessfull;
