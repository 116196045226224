import React, { useState, useEffect } from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './similarproductcarousel.css';
import ProductCard from "./productCard";

const SimilarProductCarousel = ({ products }) => {
  const [reloadKey, setReloadKey] = useState(0);

  const CustomNextArrow = ({ onClick }) => (
    <button className="slick-next custom-arrow" onClick={onClick} aria-label="Next">
      &#10095;
    </button>
  );

  const CustomPrevArrow = ({ onClick }) => (
    <button className="slick-prev custom-arrow" onClick={onClick} aria-label="Previous">
      &#10094;
    </button>
  );

  let slidesToShow = 3; // Default slidesToShow value
  if (products.length === 1 || products.length === 2) {
    slidesToShow = products.length;
  }

  const settings = {
    infinite: true,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    initialSlide: 0,
    speed: 1000,
    autoplaySpeed: 3000,
    cssEase: 'ease-in-out',
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: slidesToShow,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: slidesToShow,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: Math.min(slidesToShow, 1), // Show 1 product on smaller screens
        },
      },
    ],
  };

  const handleProductClick = (productId) => {
    setReloadKey(prevKey => prevKey + 1);
  };

  return (
    <div className="product-carousel">
      {products.length > 0 && (
        <Slider {...settings}>
          {products.map((product, index) => (
            <div key={product._id || index} className="product-item" onClick={() => handleProductClick(product._id)}>
              <div className="adjustment">
                <ProductCard product={product} product_cat={"products"} forceReload={true} />
              </div>
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};

export default SimilarProductCarousel;
